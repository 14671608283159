<template>
  <div class="sign-up-email flex justify-center items-center flex-col">
    <AriForm ref="formSignIn" class="w-6/12">
      <template v-slot="form">
        <div class="form-item">
          <span>{{ $t('email') }} <span class="required-field">*</span></span>
          <ariInput whiteBg v-model="emailInfoForm.email" :form="form" :formatter="[required, mail]" />
        </div>
        <div class="form-item">
          <span>{{ $t('authenticate.password') }} <span class="required-field">*</span></span>
          <ariInput whiteBg v-model="emailInfoForm.password" :form="form" ref="password" @update:modelValue="checkDoublePassword('repeatPassword')"
                    :formatter="[required, { fx: passwordStrength, payload: { name: signUpForm.name, surname: signUpForm.surname, birthdate : signUpForm.birthdate
          ? signUpForm.birthdate.getFullYear().toString() : '', email: emailInfoForm.email.split('@')[0] }}, { fx: password, payload: { pass1: emailInfoForm.password, pass2: emailInfoForm.repeatPassword }}]" password />
        </div>
        <div class="form-item">
          <span>{{ $t('authenticate.repeatPassword') }} <span class="required-field">*</span></span>
          <ariInput whiteBg v-model="emailInfoForm.repeatPassword" :form="form" ref="repeatPassword" @update:modelValue="checkDoublePassword('password')"
                   :formatter="[required, { fx: password, payload: { pass1: emailInfoForm.password, pass2: emailInfoForm.repeatPassword }}]" password/>
        </div>
        <div class="form-item flex pt-sm">
          <AriCheckbox v-model="emailInfoForm.tosAccepted" :form="form" :formatter="[required]">
            <span v-html="$t('authenticate.domain.pleaseTosAccepted')"></span>
            <span class="required-field">*</span>
          </AriCheckbox>
        </div>
        <div class="flex py-sm">
          <slot></slot>
        </div>
        <div class="flex buttons justify-between">
          <AriButton v-if="canGoToPreviousStep" class="w-full" :content="$t('back')"
            @click="onBack" />
          <AriButton id="next-signup-email" class="w-full" filled :content=" $t('next')"
            @click="onNext" />
        </div>
      </template>
    </AriForm>
  </div>
</template>

<script>
import { checkPasswordStrength } from '@/utils/passwordChecker';
import { password, required, mail } from '@/utils/inputFormatters';
import {passwordStrength} from "../../../utils/inputFormatters";

export default {
  name: 'SignUpEmail',
  props: {
    zone: Object,
    signUpForm: Object,
    errorHandler: Function,
    canGoToPreviousStep: Boolean
  },
  data () {
    return {
      isFormValidated: false,
      emailInfoForm: {
        email: '',
        password: '',
        repeatPassword: '',
        tosAccepted: false
      },
    };
  },
  methods: {
    passwordStrength,
    password,
    required,
    mail,
    checkDoublePassword (proc) {
      setTimeout(() => {
        this.$refs[proc].checkFormatter();
      });
    },
    selectDate (selection) {
      this.emailInfoForm.birthdate = selection;
    },
    async onNext () {
      try {
        const isFormValid = await this.$refs.formSignIn.validateForm();
        if (isFormValid) {
          this.$emit('next', this.emailInfoForm);
        }
      } catch (e) {
        console.log(e);
      }
    },
    onBack () {
      this.$emit('back');
    }
  }
};
</script>

<style lang="less">
@import "../../../assets/styles/vars.less";
@import '../../../assets/styles/mixins.less';
.sign-up-email{
  .tiny-tip {
    .rectangle-rounded();
    width: 100%;
    display: block;
    padding: 0.8rem;
    margin-top: 0.5rem;
    background: @lightorange;
    font-size: @small-text-size;
    border: 1px solid darken(@lightorange, 30%);
  }
}

.required-field {
  color:#F56C6C;
  margin-right: 4px;
}
</style>
