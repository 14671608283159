<template>
  <div class="timeslot text-base justify-between" :class="{ 'activity-indicator': handleStatus.indicator }" :title="timeslotTitle"
      :style="`background-color: ${colorBackground};--my-activity-color:  ${currentActivity.color || 'var(--ariane-primary-500)'} ;`">
    <div class="flex flex-col gap-xxs">
      <div class="text-base font-medium text-primary flex items-center justify-between">
        <div class="flex items-center gap-xs">
          <i v-if="hasLiveStream" class="fas fa-signal-stream text-xs"></i>
          <span>{{ `${timeslot.startTime} - ${timeslot.endTime}`}}</span>
        </div>
        <i v-if="timeslot.visibility === 'public'" class="fa-solid fa-users icon-public-session"></i>
      </div>
      <span class="timeslot-title">{{ timeslotTitle }}</span>
      <span class="creator-name" v-if="timeslot.host">{{ $t(timeslot.host.username, { author: timeslot.host.username }) }}</span>
      <span class="creator-name" v-else-if="memberAppConfigs['LDC_afficherCoach'] && coach">{{coach.name}}</span>
      <div class="ressource-name">
        <i class="fa fa-location-dot text-secondary"></i>
        <span>{{getResourceName()}}</span>
      </div>

    </div>
    <div class="flex items-center w-full flex-row-reverse">
      <div class="tag-status self-end" :class="handleStatus.css">
        <i v-if="handleStatus.icon" class="fas" :class="[handleStatus.icon]"></i>
        <span class="whitespace-nowrap">{{ handleStatus.text }}</span>
      </div>
      <span class="pricing-label" v-if="timeslot.tarificationType && handleStatus.css !== 'booked'">
        {{slotPricingLabel(timeslot.tarificationType)}}
      </span>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { generateCustomTheme } from '@devteam/ariane-design-system/dist/index.js';
export default {
  props: {
    timeslot: Object
  },
  data () {
    return {
      colorBackground: 'var(--ariane-content-background)'
    };
  },
  mounted() {
    if (this.currentActivity.color && this.memberAppConfigs?.LDC_coloredTimeslot) {
      const palette = generateCustomTheme(this.currentActivity.color);
      this.colorBackground = palette.lightModeTokens.theme['primary-background'];
    }
  },
  methods: {
    getResourceName () {
      return this.resources.find(r => r.id === this.timeslot.resourceId)?.name;
    },
    handleShowNbPlaces () {
      const percentageTriggerShowNbPlace = 0.25;
      const nbParticipants = this.timeslot.maxBookings - this.timeslot.freeSpots;
      switch (this.showNbPlaces) {
        case this.$CONSTANTS.SHOW_NB_PLACES.SHOW_ONLY_AVAILABLE:
          return `${this.timeslot.freeSpots} ${this.$tc('calendar.timeslot.remaining', this.timeslot.freeSpots)}`;
        case this.$CONSTANTS.SHOW_NB_PLACES.SHOW_REGISTERED_AMONG_TOTAL:
          return `${nbParticipants} / ${this.timeslot.maxBookings}`;
        case this.$CONSTANTS.SHOW_NB_PLACES.SHOW_AVAILABLE_WHEN_ALMOST_COMPLETE:
          if (this.timeslot.freeSpots <= Math.ceil(percentageTriggerShowNbPlace * this.timeslot.maxBookings)) {
            return `${this.timeslot.freeSpots} ${this.$tc('calendar.timeslot.remaining', this.timeslot.freeSpots)}`;
          } else {
            return this.$t('available');
          }
        case this.$CONSTANTS.SHOW_NB_PLACES.NO_DISPLAY:
        default:
          return this.$t('available');
      }
    }
  },
  computed: {
    ...mapState('zone', ['activities', 'resources']),
    ...mapGetters('config', ['showNbPlaces', 'slotPricingLabel']),
    ...mapState('config', ['memberAppConfigs']),
    ...mapState('zone', ['coaches']),
    coach () {
      return this.coaches.find(coach => coach.id === this.timeslot.coachId);
    },
    waitingListActivated () {
      return this.memberAppConfigs && this.memberAppConfigs.waitingListStatus !== 'disabled' && this.timeslot.visibility !== 'public';
    },
    currentActivity () {
      return this.activities.find(activity => activity.id === this.timeslot.activityId) || {};
    },
    timeslotTitle () {
      if (this.timeslot.description) {
        return this.timeslot.description;
      }
      const currentActivity = this.activities.find(activity => activity.id === this.timeslot.activityId);
      if (currentActivity) {
        return currentActivity?.name;
      }
      return '';
    },
    handleStatus () {
      let response = '';
      const status = this.timeslot.status;
      if (status === this.$CONSTANTS.STATUSES.AVAILABLE) {
        response = { text: this.handleShowNbPlaces(), css: '', indicator: true };
      } else if (this.timeslot.status === this.$CONSTANTS.STATUSES.NOT_AVAILABLE_YET) {
        return { text: this.$t('calendar.timeslot.notAvailableYet'), css: 'not-available-yet' };
      } else if (status === this.$CONSTANTS.STATUSES.BOOKED) {
        response = { text: this.$t('calendar.timeslot.alreadyBooked'), css: 'booked', icon: 'fa-check', indicator: true };
      } else if (status === this.$CONSTANTS.STATUSES.FULL && this.waitingListActivated) {
        response = { text: this.$t('calendar.timeslot.fullWithWaitingList'), css: 'full-with-waiting-list', indicator: true };
      } else if (status === this.$CONSTANTS.STATUSES.FULL) {
        response = { text: this.$t('calendar.timeslot.full'), css: 'full' };
      } else if (status === this.$CONSTANTS.STATUSES.QUEUED) {
        response = { text: this.$t('calendar.timeslot.inWaitingList'), css: 'in-waiting-list', icon: 'fa-timer in-waiting-list', indicator: true };
      } else {
        response = { text: this.$t('calendar.timeslot.unavailable'), css: 'unavailable' };
      }
      return response;
    },
    hasLiveStream () {
      return this.timeslot.isLivestream;
    }
  }
};
</script>

<style lang="less">
.tag-status.not-available-yet{
  @apply text-xxs;
}
.timeslot-title{
  @apply text-sm text-primary line-clamp-2 overflow-hidden;
}
.ressource-name {
  max-width: 100%;
  @apply text-xs font-normal text-secondary overflow-hidden text-ellipsis whitespace-nowrap gap-xs flex mt-xxs;
}
.pricing-label {
  @apply text-xxs font-light text-secondary w-full;
}
.booked-indication, .in-waiting-list-indication, .full-indication {
  border-left: 0px solid !important;
}
.activity-indicator {
  border-left: 0px solid !important;
}
.activity-indicator:before {
  @apply rounded-tl rounded-bl block h-full absolute;
  content: "";
  width: 3px;
  background-color: var(--my-activity-color);
  top: 0px;
  left: 0px;
}
.creator-name {
  @apply whitespace-nowrap overflow-ellipsis overflow-hidden text-secondary font-normal text-sm;
}
.in-waiting-list {
  color: #FDB891;
}
.full-with-waiting-list {
  @apply text-pending;
}
.full {
  @apply text-secondary-500;
}
.icon-public-session {
  color: var(--my-activity-color);
}
.tag-status {
  @apply rounded-sm px-sm py-xxs text-xs flex gap-xxs items-center;
}
.tag-status.booked {
  @apply bg-valid text-on-valid;
}
.tag-status.booked i {
  @apply text-on-valid;
}
.tag-status.in-waiting-list {
  @apply bg-pending text-on-pending;
}
.tag-status.in-waiting-list i {
  @apply text-on-pending;
}
.tag-status.full-with-waiting-list {
  border: 1px solid;
  @apply bg-transparent text-pending border-pending;
}
.tag-status.full-with-waiting-list i {
  @apply text-pending;
}
.tag-status.full {
  border: 1px solid;
  @apply bg-transparent text-error border-error;
}
</style>
