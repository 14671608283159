<template>
  <div
    class="product-details"
  >
    <DetailsMemberWeb>
      <template v-slot:header>
        <h3 class="product-details__name">
            {{ productToBuy.name }}
        </h3>
      </template>
      <template v-slot:body>
        <ProductSummary
          v-if="isProductSummaryShown"
        />
        <ProductPurchase
          v-if="isProductPurchaseFormShown"
          :hasZoneTos="hasZoneTos"
          @show-tos="onShowTos"
          @on-cancel="$emit('on-cancel')"
          @on-update-payment-type="onUpdatePaymentType"
        />
      </template>
      <template v-slot:actions>
        <ProductPurchaseConfirm
          :hasZoneTos="hasZoneTos"
          :showSaveWalletButton="showSaveWalletButton"
          :manageWalletLabel="manageWalletLabel"
          :disabled="paymentType == null || initiatePayment"
          :isRedirecting="isRedirecting"
          @on-manage-wallet="onClickManageWallet()"
          @on-buy-product="onBuyProduct()"
          @on-cancel="onCancel()"
        />
      </template>
    </DetailsMemberWeb>
    <HtmlViewerModal
      :visibility="isTOSShown"
      :dangerousHtmlContent="tosModalContent"
      @on-cancel="onCloseTos"
    />
  </div>
</template>

<script>
import ProductSummary from './ProductSummary.vue';
import ProductPurchase from './ProductPurchase.vue';
import ProductPurchaseConfirm from './ProductPurchaseConfirm.vue';
import HtmlViewerModal from '@/modules/common/HtmlViewerModal.vue';
import DetailsMemberWeb from '@/modules/common/Details.vue';
import ProductsControl from '@/modules/products/mixins/productsControl.js';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'ProductDetails',

  components: {
    ProductSummary,
    ProductPurchase,
    ProductPurchaseConfirm,
    HtmlViewerModal,
    DetailsMemberWeb
  },

  mixins: [ProductsControl],

  props: {
    currentProductId: Number
  },

  data () {
    return {
      isProductSummaryShown: false,
      isProductPurchaseFormShown: false,
      isTOSShown: false,
      justClosedTos: false,
      isRedirecting: false,
      paymentType: null
    };
  },

  computed: {
    ...mapState('user', ['wallet']),
    ...mapGetters('user', ['loggedInUser']),
    ...mapState('cart', ['productToBuy', 'zoneInfoForProduct', 'initiatePayment']),
    tosModalContent () {
      return this.zoneInfoForProduct.termsOfSale;
    },
    hasZoneTos () {
      return (
        this.zoneInfoForProduct?.termsOfSale &&
        this.zoneInfoForProduct.termsOfSale !== 'null'
      );
    },
    showSaveWalletButton () {
      return (
        this.loggedInUser &&
        this.isPaymentWithInstallments &&
        !this.wallet?.cardsList?.length
      );
    },
    manageWalletLabel () {
      return this.isRedirecting
        ? this.$t('profile.paymentMethods.redirectingToWallet')
        : this.$t('profile.paymentMethods.manageWallet');
    },
    isPaymentWithInstallments () {
      return this.paymentType === 1;
    }
  },

  mounted () {
    this.productDetailsOpened({
      loadProductDetails: this.loadProductDetails,
      showProductSummary: this.showProductSummary,
      showProductPurchaseForm: this.showProductPurchaseForm,
      handleProductDetailsOpened: this.handleProductDetailsOpened,
      handleProductDetailsOpeningError: this.handleProductDetailsOpeningError
    });
  },

  methods: {
    ...mapActions('cart', [
      'getProductDetails',
      'getZoneDetailsForProduct'
    ]),
    ...mapMutations('cart', ['SET_INITIATE_PAYMENT']),
    productDetailsOpened ({ loadProductDetails, showProductSummary, showProductPurchaseForm, handleProductDetailsOpened, handleProductDetailsOpeningError }) {
      loadProductDetails()
        .then(() => {
          showProductSummary();
          showProductPurchaseForm();
          if (handleProductDetailsOpened) {
            handleProductDetailsOpened();
          }
        }).catch(error => {
          handleProductDetailsOpeningError(error);
        });
    },
    showProductSummary () {
      this.isProductSummaryShown = true;
    },
    showProductPurchaseForm () {
      this.isProductPurchaseFormShown = true;
    },
    async loadProductDetails () {
      try {
        await this.getProductDetails(this.currentProductId);
        if (this.productToBuy) {
          await this.getZoneDetailsForProduct(this.zoneForTos(this.productToBuy.zone));
        }
      } catch (error) {
        this.$information.capture({ error, inform: true });
        this.$information.alert({
          message: this.$t('calendar.errors.GENERAL_ERROR')
        });
      }
    },
    handleProductDetailsOpeningError (error) {
      this.$information.capture({ error });
      this.$information.alert({
        message: this.$t('calendar.errors.GENERAL_ERROR')
      });
    },
    async onShowTos () {
      this.isTOSShown = true;
    },
    onCloseTos () {
      this.justClosedTos = true;
      this.isTOSShown = false;
    },
    handleProductDetailsOpened () {
      this.$emit('on-loaded');
    },
    onPayFull () {
      this.isPaymentWithInstallments = false;
    },
    onPayWithInstallments () {
      this.isPaymentWithInstallments = true;
    },
    async onClickManageWallet () {
      this.isRedirecting = true;
      try {
        await this.registerWallet();
      } catch (error) {
        this.isRedirecting = false;
        this.$information.capture({
          error,
          inform: true,
          component: 'MyPaymentMethods',
          method: 'onClickManageWallet'
        });
      }
    },
    onCancel () {
      this.$emit('on-cancel');
    },
    onBuyProduct () {
      this.SET_INITIATE_PAYMENT(true);
    },
    onUpdatePaymentType (paymentType) {
      this.paymentType = paymentType;
    }
  }
};
</script>

<style lang="less">
.product-details{
  height: 100%;
  .product-details__name{
    padding-bottom: 1rem;
    color:var(--ariane-primary-400);
    filter: brightness(90%);
  }
}
</style>
