<template>
  <div class="mobile-apps" :class="{'small-badge': smallFormat}">
    <div class="mobile-app__badge" v-if="appStoreUrls?.PLAYSTORE">
      <MobileAppBadge
        :storeDeepLink="buildGooglePlayUrl(appStoreUrls.PLAYSTORE)"
        :linkImgUrl="googlePlayStoreIconUrl()"
        :linkImgAlt="$t('getOnGooglePlay')"
      />
    </div>
    <div class="mobile-app__badge" v-if="appStoreUrls?.APPSTORE">
      <MobileAppBadge
        :storeDeepLink="buildAppleStoreUrl(appStoreUrls.APPSTORE)"
        :linkImgUrl="appStoreIconUrl()"
        :linkImgAlt="$t('getOnAppStore')"
      />
    </div>
    <div class="mobile-app__badge" v-if="appStoreUrls?.APPGALLERY">
      <MobileAppBadge
        :storeDeepLink="appStoreUrls.APPGALLERY"
        :linkImgUrl="AppGalleryStoreIconUrl()"
        :linkImgAlt="$t('getOnAppGallery')"
      />
    </div>
  </div>
</template>
<script>
import MobileAppBadge from '@/modules/common/generic/MobileAppBadge.vue';
import { mapGetters } from 'vuex';
import appStoreIcon from '@/assets/images/stores/app-store-fr.svg';
import googlePlayIcon from '@/assets/images/stores/google-play-badge-fr.png';
import appGalleryIcon from '@/assets/images/stores/app-gallery-badge-fr.png';
export default {
  name: 'MobileApps',

  components: {
    MobileAppBadge
  },
  props: {
    smallFormat: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {};
  },
  methods: {
    appStoreIconUrl () {
      return appStoreIcon;
    },
    googlePlayStoreIconUrl () {
      return googlePlayIcon;
    },
    AppGalleryStoreIconUrl () {
      // return import(`@/assets/images/stores/${this.$t('getOnAppGalleryImageUrl')}`);
      return appGalleryIcon;
    },
    buildGooglePlayUrl(url) {
      return url.indexOf('play.google.com') !== -1 ? url + '&referrer=utm_source%3DmemberWebApp%26utm_medium%3DinfoModal%26utm_campaign%3DVisitorsFromMemberWebApp%26anid%3Dadmob&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' : url;
    },
    buildAppleStoreUrl(url) {
      return url.indexOf('apps.apple.com') !== -1 ? url + '?itsct=apps_box&amp;itscg=30200' : url;
    }
  },
  computed: {
    ...mapGetters('config', ['appStoreUrls']),
  }
};
</script>

<style lang="less">
.mobile-apps{
  text-align: center;
  margin-top: 40px;
  width: 100%;
  .mobile-app__badge{
    display: inline-block;
    width: 50%;
    max-width: 200px;
  }
}
.mobile-apps.small-badge .mobile-app__badge {
  width: 30%;
  max-width: 125px;
  @apply pr-xs;
}
</style>
