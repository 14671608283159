import {
  memberAPI,
  deciplusMemberAPI,
  COMMON_URLS
} from '../request';

import { findDomain, adaptUrl } from '@/utils/url';

export default {
  getGlobalAccounts: ({ reinitToken }) => {
    const url = adaptUrl('accounts', {
      queries: {
        reinitToken,
        domain: findDomain()
      }
    });
    return deciplusMemberAPI.get(url);
  },
  getPassedBookings: ({ page }) => {
    const url = adaptUrl('/bookings/passed', { queries: { page } });
    return memberAPI.get(url);
  },
  getDomainUserInfo: () => memberAPI.get('me', { params: { domain: findDomain() } }),
  setUserConfig: (name, body) => {
    const url = 'config/:name'.replace(':name', name);
    deciplusMemberAPI.patch(url, body);
  },
  updateDomainUserInfo: (userData) => memberAPI.put('me', userData),
  getClubInfo: () => deciplusMemberAPI.get('clubs'),
  linkClubAccountToGlobalAccount: ({ email, password }) => deciplusMemberAPI.post('clubs/link', { email, password, domain: findDomain() }),
  loadUserProducts: () => memberAPI.get('subscription'),
  getManageWalletUrl: (replaceWallet = false) => `${COMMON_URLS.BASE_URL}members/v1/wallet/manage${replaceWallet ? '?replaceWallet=true' : ''}`,
  getWallet: () => {
    return memberAPI.get('wallet');
  },
  manageWallet: async (returnURL) => {
    const { data } = await memberAPI.post(
      'wallet', { returnURL }
    );
    return data;
  },
  getBalance: () => {
    return memberAPI.get('balance');
  }
};
