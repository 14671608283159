<template>
  <AriModal v-if="modalVisible" :showClose="false" :modalTitle="$t('privacyPolicyModal.title')">
    <template #body>
      <span>{{ $t('privacyPolicyModal.body') }}</span>
      <br>
      <a class=' text-secondary cursor-pointer underline link' href='https://www.xplortechnologies.com/fr/confidentialite' target='_blank'>{{$t('privacyPolicyModal.clickHere')}}</a>
      <span>&nbsp; {{$t('privacyPolicyModal.toConsult')}} </span>
    </template>
    <template #footer>
      <div class="flex justify-between">
        <ariButton :content="$t('app.accept')" @click="accepteAndContinue()" />
        <ariButton :content="$t('app.refuseAndQuit')" error filled @click="refuseAndLogout()"/>
      </div>
    </template>
  </AriModal>
</template>

<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed } from 'vue';
import services from '@/services';
const store = useStore();
const router = useRouter();

const refuseAndLogout = function () {
  store.dispatch('user/logout');
  router.replace({ name: 'signIn' });
};
const modalVisible = computed(() => {
  return store.getters['user/loggedInUser'] && !store?.state?.user?.userDomainInfo?.privacyPolicy;
});
const accepteAndContinue = function () {
  services.HTTP.user.updateDomainUserInfo({ privacyPolicy: true }).then(res => {
    store.dispatch('user/loadDomainUserInfo');
  });
};
</script>

<style>
</style>

<style scoped>

</style>
