<template>
  <div class="h-full">
    <div v-if="!hasWebPayment" class="full-page-text">
      {{ $t('products.domainHasNoWebPayment') }}
    </div>
    <div class="main-container h-full">
      <div class="flex p-xs self-start ml-sm xl:ml-0">
        <div class="cursor-pointer" @click="goToShop()">
          <i class="fa fa-chevron-left"></i>
          <span class="p-xs">{{ $t('cart.goToShop') }}</span>
        </div>
      </div>
      <div class="item-summary-container">
        <div class="fg-2 w-11/12 sm:w-4/5 overflow-auto px-default lg:px-0 h-0 xl:h-full gap-xs flex flex-col">
          <h2 class="cart-title"> {{ $t('cart') }} </h2>
          <div v-if="isProductInCart" class="text-center product-pickup py-xs px-lg">
            <span>{{ $t('cart.product.pickup')}}</span>
          </div>
          <template v-if="sortedProducts.length">
            <div v-for="product in sortedProducts" :key="product.config.id">
              <CartItem :product="product" />
            </div>
          </template>
          <span v-else>{{ $t('cart.empty') }}</span>
        </div>
        <div class="w-full xl:w-auto">
          <CartSummary v-if="isMounted" ref="summary" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';

import ProductsControl from '@/modules/products/mixins/productsControl.js';
import CartItem from './components/cartItem.vue';
import CartSummary from './components/cartSummary.vue';

export default {
  name: 'page.cart',
  mixins: [ProductsControl],
  components: {
    CartItem,
    CartSummary
  },
  data () {
    return {
      isMounted: false
    };
  },
  async mounted () {
    this.INIT_PRODUCTS();
    this.checkProductsStock();
    try {
      if (!this.rearrangeUrlAfterTransaction()) {
        this.reactToTransactionResponse();
        // this.reactToActivityFilter();
        // this.reactToProductFilter();
        // this.reactToMemorisedProduct();
        await this.prepareProducts();
        this.updateCartProductsConfig();
        this.loadVideos();
        if (this.loggedInUser) {
          await this.getZoneDetailsForProduct(this.usersZoneDetails.id);
          this.loadUserWallet();
          if (this.$route.query.productId) {
            this.addProduct({ id: this.$route.query.productId });
          }
        }
        this.isMounted = true;
      }
    } catch (error) {
      this.$information.capture({ error, inform: true });
      this.$information.alert({
        message: this.$t('calendar.errors.GENERAL_ERROR')
      });
    }
  },
  computed: {
    ...mapGetters('user', ['loggedInUser', 'usersZoneDetails']),
    ...mapGetters('cart', ['sortedProducts', 'isProductInCart']),
    ...mapState('cart', ['initiatePayment', 'pendingResa', 'products', 'useCredit']),
    ...mapState('user', ['bookingDuringPurchase'])
  },
  methods: {
    ...mapMutations('cart', ['SET_INITIATE_PAYMENT', 'SET_USE_CREDIT', 'INIT_PRODUCTS']),
    ...mapActions('cart', ['updateCartProductsConfig', 'getZoneDetailsForProduct', 'checkProductsStock', 'addProduct']),
    ...mapActions('user', ['loadUserWallet']),
    ...mapActions('zone', ['loadVideos']),
    goToShop () {
      this.$router.go(-1);
    },
    async _onBuyProduct () {
      if (!this.loggedInUser) {
        this.alertSignInMandatory();
        return this.redirectToSignIn();
      }
      const purchaseDetails = this.getPurchaseDetails();
      return this.onBuy({
        returnURL: this.returnURLForPurchase,
        products: purchaseDetails,
        useCredit: this.useCredit
      });
    },
    getPurchaseDetails () {
      const purchaseDetails = [];
      for (const [id, product] of Object.entries(this.products)) {
        purchaseDetails.push({
          productId: id,
          quantity: product.quantity,
          cashPayment: product.cashPayment,
          giftConfig: product.giftConfig,
          isGift: product.isGift,
          code: product.code
        });
      }
      if (this.bookingDuringPurchase) {
        purchaseDetails.booking = { id: this.bookingDuringPurchase.id };
        if (this.bookingDuringPurchase.status === this.$CONSTANTS.STATUSES.FULL) {
          purchaseDetails.booking.isQueueing = 1;
        }
        if (this.bookingDuringPurchase.placeId) {
          purchaseDetails.booking.placeId = this.bookingDuringPurchase.placeId;
        }
      }
      return purchaseDetails;
    }
  },
  watch: {
    initiatePayment () {
      if (this.initiatePayment) {
        this._onBuyProduct().catch((error) => {
          this.SET_INITIATE_PAYMENT(false);
          this.SET_USE_CREDIT(false);
          this.$information.capture({ error, inform: true });
          this.$information.alert({
            message: this.$t('calendar.errors.GENERAL_ERROR')
          });
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.main-container {
  @apply xl:p-md flex flex-col w-full items-center xl:items-start xl:max-w-7xl xl:m-auto;
}
.cart-title {
  @apply py-xs md:py-md font-medium text-primary;
}
.item-summary-container {
  @apply flex flex-col xl:flex-row justify-between gap-sm md:gap-lg md:flex-grow w-full items-center xl:items-start h-full;
}
.product-pickup {
  border: 1px solid var(--ariane-accent-text-on-pending-background);
  @apply font-extralight bg-pending-background rounded text-on-pending-background;
}
</style>
