import { createApp, h } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { Vue3Mq, MqResponsive } from 'vue3-mq';
import ElementPlus from 'element-plus';
import i18n from '@/services/translation/i18n';
import HTTP from '@/services/HTTP';
import * as datetime from '@/utils/datetime';
import CONSTANTS from '@/utils/constants';
import { findDomain } from '@/utils/url';
import { capture, alert, track } from '@/utils/information';
import * as Sentry from '@/utils/sentry';
import InteractiveDiv from '@/modules/common/generic/InteractiveDiv.vue';
import VueSocialSharing from 'vue-social-sharing';
import services from '@/services';
import inputNumber from '@/core/components/inputNumber.vue';
import ArianeComponents from '@devteam/ariane-components';
import FloatingVue from 'floating-vue';
import '@devteam/ariane-components/dist/ariane-components.css';
import './tailwind.css';
import FlouxInput from '@/core/components/flouxInput.vue';
const app = createApp({
  render: () => h(App)
});
app.use(FloatingVue);

// On a fait sauter vue-mq vue-infinite-loading
import fr from 'element-plus/dist/locale/fr.mjs';

// On set l'i18n de ElementUi en fr ==> Datepicker firstDayOfWeek: Monday
import 'dayjs/locale/fr';

const elementLocales = {
  'fr-FR': fr,
  'fr': fr
};
const language = navigator.language || navigator.userLanguage;

app.use(ElementPlus, {
  locale: elementLocales[language] || ''
});
app.use(ArianeComponents);
// Vue.use(VueMq, {
//   breakpoints: {
//     sm: CONSTANTS.DEVICE_FORMAT_BREAKPOINTS.FROM_SM_TO_MD,
//     md: CONSTANTS.DEVICE_FORMAT_BREAKPOINTS.FROM_MD_TO_LG,
//     lg: Infinity
//   }
// });

app.use(VueSocialSharing);
app.use(Vue3Mq, { preset: 'tailwind' });
app.component('mq-responsive', MqResponsive);
app.component('interactive-div', InteractiveDiv);
app.component('InputNumber', inputNumber);
app.component('FlouxInput', FlouxInput);

Object.defineProperty(app.config.globalProperties, '$services', { value: services });
Object.defineProperty(app.config.globalProperties, '$HTTP', { value: HTTP });
Object.defineProperty(app.config.globalProperties, '$CONSTANTS', { value: CONSTANTS });
Object.defineProperty(app.config.globalProperties, '$datetime', { value: datetime });
Object.defineProperty(app.config.globalProperties, '$information', { value: { capture, alert, track } });

// const { isNavigationFailure, NavigationFailureType } = VueRouter;
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push (location) {
//   return originalPush.call(this, location).catch(err => {
//     if (!isNavigationFailure(err, NavigationFailureType.redirected) && !isNavigationFailure(err, NavigationFailureType.duplicated) && !isNavigationFailure(err, NavigationFailureType.cancelled)) throw err;
//   });
// };
// const originalReplace = VueRouter.prototype.replace;
// VueRouter.prototype.replace = function replace (location) {
//   return originalReplace.call(this, location).catch(err => {
//     if (!isNavigationFailure(err, NavigationFailureType.redirected) && !isNavigationFailure(err, NavigationFailureType.duplicated) && !isNavigationFailure(err, NavigationFailureType.cancelled)) throw err;
//   });
// };

if (import.meta.env.VITE_NODE_ENV === 'production') {
  Sentry.sentryInit();
  app.config.globalProperties.$sentry = Sentry;
} else {
  app.config.globalProperties.$sentry = {
    captureExceptionWithTags: function (err, params) {
      console.error(err);
    },
    captureMessage: function (msg, params) {
      console.error(msg);
    }
  };
}

Promise.all([
  store.dispatch('domain/setDomain', findDomain()),
  store.dispatch('domain/getZones'),
  store.dispatch('config/loadMemberAppConfigurations'),
]).then(res => res)
  .catch(error => {
    if (error?.response?.status === 404) {
      router.push({ name: 'errorPage' });
    } else {
      capture({
        error,
        inform: true,
        component: 'main.js',
        method: 'setDomain&getZones&getDomainConfig'
      });
    }
  })
  .finally(() => {
    app.use(router).use(store).use(i18n).mount('#app');
    // if (window.Cypress) {
    //   window.appcontainer = appContainer;
    // }
  });
