<template>
  <div class="flex justify-center flex-col items-center">
    <AriForm ref="formSignupPersonalInfo" class="py-default w-full px-sm lg:px-0 lg:w-6/12 flex flex-col gap-sm">
      <template v-slot="form">
        <div class="flex flex-col gap-sm items-baseline">
          <span>{{ $t('name') }} <span class="required-field">*</span></span>
          <ariInput whiteBg :form="form" v-model="personalInfoForm.name" :formatter="required" />
        </div>
        <div class="flex flex-col gap-sm items-baseline">
          <span>{{ $t('surname') }} <span class="required-field">*</span></span>
          <ariInput whiteBg :form="form" v-model="personalInfoForm.surname" :formatter="required" />
        </div>
        <BirthdayInput ref="birthdateInput" @on-date-change="selectDate" />
      </template>
    </AriForm>
    <div class="flex buttons justify-between  px-sm lg:px-0 w-full lg:w-6/12">
      <AriButton class="w-full" outlined v-if="canGoToPreviousStep" :content=" $t('back')" @click="onBack" />
      <AriButton id="next-signup-personal" class="w-full" filled :content=" $t('next')" @click="onNext" />
    </div>
  </div>
</template>

<script>
import BirthdayInput from '@/modules/common/generic/BirthdayInput.vue';
import { required } from '@/utils/inputFormatters';

export default {
  name: 'SignUpPersonalInfo',
  components: {
    BirthdayInput
  },
  props: {
    rules: Object,
    errorHandler: Function,
    canGoToPreviousStep: Boolean
  },
  data () {
    return {
      personalInfoForm: {
        name: '',
        surname: '',
        birthdate: null
      }
    };
  },
  methods: {
    required,
    selectDate (selection) {
      this.personalInfoForm.birthdate = selection;
    },
    async onNext () {
      try {
        const isBirthdateValid = this.$refs.birthdateInput.validate();
        const isFormValid = await this.$refs.formSignupPersonalInfo.validateForm();
        if (isFormValid && isBirthdateValid) {
          this.$emit('next', this.personalInfoForm);
        }
      } catch (err) {
        this.errorHandler(err, this.$t('authenticate.pleaseLoginOrCheckCredentials'));
      }
    },
    onBack () {
      this.$emit('back');
    }
  }
};
</script>

<style lang="less">
.required-field {
  color:#F56C6C;
  margin-right: 4px;
}

</style>
