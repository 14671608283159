import store from '@/store';
import { ElMessage } from 'element-plus';
import i18n from '@/services/translation/i18n';
import { areRoutesForPageOpen } from '@/utils/publicPages';
const { t } = i18n.global;

export function isUserLoggedIn () {
  return store.getters['user/loggedInUser'];
}

export async function attemptAutoSignIn () {
  await store.dispatch('user/loadDomainUserInfo')
    .catch(err => err);
}

export async function attemptLoadConfigs () {
  await store.dispatch('config/loadMemberAppConfigurations')
    .catch(err => err);
}

export function displayProtectedPageWarning () {
  ElMessage({
    message: t('authenticate.loginFirst'),
    showClose: true
  });
}

export function arePublicRoutesForNextPageOpen (nextPage) {
  const publicRoutesScopes = store.getters['config/publicRoutes'];
  if (!publicRoutesScopes) {
    return false;
  }
  return areRoutesForPageOpen(nextPage, publicRoutesScopes);
}

export function selectedZoneForcedByTunnel () {
  return store.getters['config/forcedZoneByTunnel'];
}

export function zoneForProducts () {
  return store.getters['config/zoneShopFilterByTunnel'];
}

export function isMulti () {
  return store.getters['domain/isMultiSite'];
}
