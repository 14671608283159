<template>
  <div @click.stop>
    <AriModal v-if="show" :modalTitle="$t('cart.gift') + ' : ' + prestation.name" fitContent @close="$emit('on-close')">
      <template #body>
          <!-- <h2 class="text-lg font-semibold ariane-mb-xs">{{ $t('cart.gift') + ' "' + prestation.name + '"' }}</h2> -->
          <div class="gift-modal-content">
            <div class="mb-default flex items-center">
              <div class="pr-xs" v-html="getText"></div>
              <AriToggle v-model="showInputs" />
            </div>
            <div v-show="showInputs">
              <!-- <AriInput center :placeholder="$t('cart.gift.name')" v-model="name" /> -->
              <AriInput class="mb-default" ref="inputMail" center :placeholder="$t('cart.gift.email')" :formatter="[mail,required]" v-model="email"/>
              <div class="flex flex-col gap-xxs">
                  <textarea :placeholder="$t('cart.gift.customMessage')" rows="5" class="w-full no-resize gift-message" v-model="message" />
              </div>
            </div>
          </div>
      </template>
      <template #footer>
        <div class="flex justify-end mt-default gap-xxs">
          <AriButton class="ariane-mt-base" :content="$t('close')" outline @click="close()" />
          <AriButton class="ariane-mt-base" :content="$t('cart.addToCart')" filled @click="close(true)" />
        </div>
      </template>
    </AriModal>
  </div>
</template>

<script>
import ProductsControl from '@/modules/products/mixins/productsControl.js';
import { mail, required } from '@/utils/inputFormatters';
import { mapGetters } from 'vuex';

export default {
  name: 'giftModal',
  mixins: [ProductsControl],
  data () {
    return {
      // name: null,
      email: null,
      message: null,
      showInputs: false
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    prestation: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters('user', ['userEmail']),
    getText () {
      let key = 'cart.gift.text';
      key += this.showInputs ? '.mail' : '';
      return this.$t(key);
    }
  },
  methods: {
    mail,
    required,
    close (add = false) {
      if (add) {
        setTimeout(() => {
          const isMailOk = !this.showInputs || this.$refs.inputMail.checkFormatter();
          if (isMailOk) {
            this.$emit('on-close', {
              item: this.prestation,
              giftConfig: {
                email: this.email || this.userEmail,
                message: this.message
              }
            });
          }
        }, 500);
      } else {
        this.$emit('on-close');
      }
    }
  }
};
</script>

<style lang="less" scoped>
.gift-modal-content {
  max-width: 400px;
}
.gift-message {
  @apply border-solid text-trivial-400 rounded-sm p-xxs;
  border-width: 1px;
  outline: none;
}
</style>
