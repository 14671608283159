import {
  isUserLoggedIn,
  arePublicRoutesForNextPageOpen,
  attemptLoadConfigs,
  displayProtectedPageWarning,
  attemptAutoSignIn
} from './guardHelper';
import store from '../../store';
import router from '@/router';

export async function publicPageGuard (to, from, next) {
  await attemptAutoSignIn();
  if (isUserLoggedIn()) {
    next();
  } else if (arePublicRoutesForNextPageOpen(to.name)) {
    next();
  } else {
    await attemptLoadConfigs();
    if (!store.state.config.memberAppConfigs.resa_en_ligne) {
      router.push({ name: 'errorPage' });
    } else if (arePublicRoutesForNextPageOpen(to.name)) {
      next();
    } else {
      displayProtectedPageWarning();
      router.push({
        path: `/${to.params.domain}/signIn`,
        query: { to: to.name }
      });
    }
  }
}

export async function discoveryPageGuard (to, from, next) {
  if (!store.state.config.memberAppConfigs.LDC_afficherPageDecouverte && from.name !== 'tunnel') {
    router.push({ path: `/${to.params.domain}/home` });
  } else next();
}
