<template>
  <div class="h-full" data-theme="main">
    <router-view />
    <modalMandatoryFields v-if="!modalPrivacyVisible"/>
    <modalLocalStorageUnavailable/>
    <modalMigrationWallet v-if="!modalPrivacyVisible && userDomainInfo"/>
    <modalPrivacyPolicy v-if="modalPrivacyVisible" />
  </div>
</template>

<script>
import './assets/styles/styles.less';
import './assets/styles/extra.less';

import 'element-plus/theme-chalk/index.css';
import modalMandatoryFields from '@/core/components/modalMandatoryFields.vue';
import modalLocalStorageUnavailable from '@/core/components/modalLocalStorageUnavailable.vue';
import modalMigrationWallet from '@/core/components/modalMigrationWallet.vue';
import modalPrivacyPolicy from '@/core/components/modalPrivacyPolicy.vue';
import { mapState, mapGetters } from 'vuex';
export default {
  name: 'App',
  components: {
    modalMandatoryFields,
    modalLocalStorageUnavailable,
    modalMigrationWallet,
    modalPrivacyPolicy
  },
  computed: {
    ...mapState('user', ['userDomainInfo']),
    ...mapGetters('user', ['loggedInUser']),
    modalPrivacyVisible () {
      return this.loggedInUser && this.userDomainInfo && !this.userDomainInfo?.privacyPolicy;
    }
  },
  mounted () {
    // if (this.loggedInUser && !this.userDomainInfo.privacyPolicy) {
    //   const titleAlert = this.$t('privacyPolicyModal.title');
    //   const titleHTML = this.$t('privacyPolicyModal.body');
    //   this.$ariConfirm(this.$t('privacyPolicyModal.title'), this.$t('privacyPolicyModal.body'), {
    //     html: this.$t('privacyPolicyModal.body'),
    //     allowOutsideClick: false,
    //     allowEscapeKey: false,
    //     showCancelButton: false,
    //     confirmButtonText: this.$t('app.accept'),
    //     denyButtonText: this.$t('app.refuseAndQuit'),
    //     showDenyButton: true,
    //     customClass: { actions: 'gap-5 force-between', container: 'big-zindex', denyButton: 'ml-0' }
    //   }).then((res) => {
    //     if (res.isConfirmed) {
    //       this.$services.HTTP.user.updateDomainUserInfo({ privacyPolicy: true });
    //     } else {
    //       this.$ariAlert(titleAlert, titleHTML, {
    //         html: titleHTML,
    //       });
    //       this.logout();
    //       this.$router.replace({ name: 'signIn' });
    //     }
    //   });
    // }
  }
};
</script>
