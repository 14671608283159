import {
  publicAPI,
  memberAPI
} from '../request';
import { findDomain, adaptUrl } from '@/utils/url';

export default {
  getPublicProducts: ({ zoneId } = {}) => {
    const url = adaptUrl(':domain/products', {
      params: {
        domain: findDomain()
      },
      queries: {
        zoneId
      }
    });
    return publicAPI.get(url);
  },
  getProducts: () => {
    return memberAPI.get('products');
  },
  getProduct: (productId) => {
    const url = adaptUrl('products/:productId', {
      params: {
        productId
      }
    });
    return memberAPI.get(url);
  },
  getPromotionByCode (promoCode) {
    return memberAPI.get(`products/promotion/${encodeURIComponent(promoCode)}`);
  },
  getProductWithPromotion: ({ productId, code }) => {
    const url = adaptUrl('products/:productId', {
      params: {
        productId
      },
      queries: {
        code
      }
    });
    return memberAPI.get(url);
  },
  addProductToCart: async purchaseDetails => {
    const { data } = await memberAPI.post('cart/current', purchaseDetails);
    return data.cartId;
  },
  processCart: async (cartId, returnURL, useCredit) => {
    const { data } = await memberAPI.post('cart/current/checkout', {
      cartId,
      returnURL,
      useCredit
    });
    return data.paymentURL || data.result?.shortMessage;
  }
};
