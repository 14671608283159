import {
  memberAPI,
  deciplusMemberAPI
} from '../request';
import * as datetime from '@/utils/datetime';
import { findDomain } from '@/utils/url';
export default {
  signInGlobal: ({ email, password } = {}) => {
    const signInData = {
      email,
      password,
      domain: findDomain(),
      limitToDomain: true
    };
    return deciplusMemberAPI.post('authenticate', signInData, { withCredentials: true });
  },
  isDomainSignUpAvailable: ({ email }) => {
    const simulationData = {
      domain: findDomain(),
      email
    };
    return memberAPI.post('simulate/authenticate', simulationData);
  },
  signUpForDomain: ({ zone, tosAccepted, email, name, surname, gender, birthdate, password } = {}) => {
    const signUpData = {
      domain: findDomain(),
      zone,
      tosAccepted,
      email,
      name,
      surname,
      gender,
      birthdate: datetime.toISODate(birthdate),
      password
    };
    return memberAPI.post('signup', signUpData);
  },
  signInClub: ({ email, password }) => {
    const signInData = {
      login: email,
      password,
      domain: findDomain()
    };
    return memberAPI.post('authenticate', signInData);
  },
  signInWithFacebook: (accessToken = '') =>
    deciplusMemberAPI.post('authenticate-facebook', {
      accessToken,
      domain: findDomain(),
      limitToDomain: true
    }, { withCredentials: true }),
  signInWithGoogle: (accessToken = '') =>
    deciplusMemberAPI.post('authenticate-google', {
      accessToken,
      domain: findDomain(),
      limitToDomain: true
    }, { withCredentials: true }),
  startResetPassword: ({ email }) =>
    memberAPI.post('password-reinit/start', {
      email,
      domain: findDomain(),
      sendEmail: true
    }),
  resetPasswordAtDomain: ({ newPassword, reinitToken }) =>
    memberAPI.post('password-reinit/complete', {
      newPassword,
      reinitToken,
      domain: findDomain()
    }),
  confirmResetPassword: ({ email, pin, newPassword }) =>
    deciplusMemberAPI.post('reset-password', {
      email,
      pin,
      newPassword
    })
};
