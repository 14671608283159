<template>
  <div>
    <AriCheckbox class="py-xs" v-model="zoneConfirmationsForm.canContact" @change="onCanContactChange" >
      {{ $t('authenticate.domain.canContactResponse', { clubName: zone.clubName }) }}
    </AriCheckbox>
  </div>


</template>

<script>
export default {
  name: 'SignUpZoneConfirmations',
  props: {
    zone: Object
  },
  data () {
    return {
      zoneConfirmationsForm: {
        canContact: false,
      }
    };
  },
  methods: {
    onCanContactChange (change) {
      this.$emit('canContactChanged', change.target.checked);
    }
  }
};
</script>
