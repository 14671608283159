<template>
  <ariModal v-if="isVisible" :modalTitle="$t('authenticate.recover.modalTitle')" @close="isVisible = false">
    <template #body>
      <div class="p-default">
        <div v-show="reinitTriggered">
          {{ $t('authenticate.reinit.reinitTriggered') }}
        </div>
        <ariInput :label="$t('authenticate.recover.title')" v-if="!reinitTriggered" ref="inputMail" v-model="recoverAccountForm.email" :formatter="[required, mail]" />
      </div>
    </template>
    <template #footer>
      <AriButton v-if="!reinitTriggered" class="m-auto" filled :content="$t('authenticate.recover.getPin')" @click="onResetPassword" />
      <AriButton v-else text :content="$t('app.return')" @click="isVisible = false"/>
    </template>
  </ariModal>
</template>

<script>
import { mail, required } from '@/utils/inputFormatters';

export default {
  name: 'ModalRecoverAccount',

  props: {
    visibility: Boolean,
    email: String
  },

  computed: {
    isVisible: {
      get () {
        return this.visibility;
      },
      set () {
        this.$emit('on-cancel');
      }
    }
  },

  data () {
    return {
      reinitTriggered: false,
      recoverAccountForm: {
        email: ''
      },
      recoverAccountFormRules: {
        email: [
          {
            required: true,
            message: this.$t('authenticate.pleaseInputEmail'),
            trigger: 'change'
          },
          {
            required: true,
            message: this.$t('authenticate.pleaseInputEmail'),
            trigger: 'blur'
          }
        ]
      }
    };
  },

  methods: {
    required,
    mail,
    updateEmail () {
      this.recoverAccountForm.email = this.email;
    },
    async onResetPassword () {
      try {
        const isFormValid = !!this.$refs.inputMail.checkFormatter();
        if (!isFormValid) {
          throw new Error();
        }
        await this.$services.HTTP.authenticate.startResetPassword({ email: this.recoverAccountForm.email });
        this.reinitTriggered = true;
      } catch (error) {
        this.$information.alert({
          message: this.$t('authenticate.recover.pleaseCheckInfo')
        });
        this.$information.capture({ error });
      }
    }
  }
};
</script>

<style lang='less'>
#domain-account-reset{
  text-align: center;
}
.modal-recover-account__action-container{
  width: 25%;
  margin: auto;
}
</style>
