<template>
  <div class="my-scheduled-payments">
    <div class="my-profile__section">
      <DeciTitle showDivider :text="$t('profile.scheduledPayments.title')" />
      <div 
        class="my-scheduled-payments__payments-container--unpaid mb-sm"
        v-if="preparedUnpaidScheduledPayments.length"
      >
        <div class="flex justify-end">
          <AriButton
            v-if="defaultCard && zoneDetails && zoneDetails.termsOfSale"
            :content="`${$t('profile.scheduledPayments.payUnpaid2')} : ${selectedUnpaidPrice} ${currency}`"
            @click="showModal = true"
            filled
            class="mt-sm"
          />
          <AriButton
            v-if="!defaultCard && zoneDetails && zoneDetails.termsOfSale"
            :content="`${$t('profile.scheduledPayments.addCard')}`"
            @click="showModalWallet = true"
            filled
            class="mt-sm"
          />
        </div>

        <p class="font-medium text-base text-primary flex mb-sm">
          {{ $t('profile.scheduledPayments.myUnpaidPayments') }}
        </p>

        <el-table
          :data="preparedUnpaidScheduledPayments"
          :show-header="false"
          @selection-change="handleSelectionChange"
          style="width: 100%">
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column
            prop="date"
            label="date"
            min-width="75"
          >
          </el-table-column>
          <el-table-column
            prop="productName"
            label="productName"
            min-width="75"
          >
          </el-table-column>
          <el-table-column
            prop="paymentType"
            label="paymentType"
            min-width="100"
          >
            <template v-slot="scope">
              {{ formatPaymentType(scope.row.paymentType) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="price"
            label="price"
            min-width="75"
          >
          </el-table-column>
          <el-table-column
            prop="renewal"
            label="renewal"
            min-width="75"
          >
          </el-table-column>
        </el-table>
      </div>
      <div
        class="my-scheduled-payments__payments-container"
        v-if="preparedScheduledPayments.length"
      >
        <p class="font-medium text-base text-primary flex mb-sm">
          {{ $t("profile.scheduledPayments.myUpcomingPayments") }}
        </p>
        <el-table
          :data="preparedScheduledPayments"
          :show-header="false"
          style="width: 100%">
          <el-table-column
            style="display: none"
            width="55">
          </el-table-column>
          <el-table-column
            prop="date"
            label="date"
            min-width="75"
          >
          </el-table-column>
          <el-table-column
            prop="productName"
            label="productName"
            min-width="75"
          >
          </el-table-column>
          <el-table-column
            prop="paymentType"
            label="paymentType"
            min-width="100"
          >
            <template v-slot="scope">
              {{ formatPaymentType(scope.row.paymentType) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="price"
            label="price"
            min-width="75"
          >
          </el-table-column>
          <el-table-column
            prop="renewal"
            label="renewal"
            min-width="75"
          >
          </el-table-column>
        </el-table>
      </div>
      <el-col
        v-if="noPaymentFound"
      >
        {{ $t('profile.scheduledPayments.notFound') }}
      </el-col>
    </div>
    <ModalManageWallet :visibility="showModalWallet" @on-close="showModalWallet = false" />
    <AriModal v-if="showModal" @close="showModal = false" maxWidth="650px">
      <template #body>
        <div>
          <div class="loader-container">
            <i v-if="paymentIsLoading" class="fa-duotone fa-spinner fa-spin" />
          </div>
          <p class="font-medium text-base text-primary flex mb-sm">
            {{ $t('profile.scheduledPayments.paymentRegul') }}
          </p>
          <p>
            {{ $t('profile.scheduledPayments.youAreDoingRegul') }}
            {{ selectedUnpaidPrice }} {{ currency }}
          </p>
          <p>
            {{ $t('profile.scheduledPayments.byValidationYouAgreeToTos') }}
          </p>
          <div class="flex justify-center">
            <AriButton
              text
              :content="$t('profile.scheduledPayments.tos')"
              @click="showTosModal = true"
            />
          </div>
          <AriButton
            @click="onPayUnpaidSchedules"
            :content="$t('profile.scheduledPayments.payUnpaid1')"
            filled
            class="mt-sm flex justify-center w-full"
          />
        </div>
      </template>
    </AriModal>

    <AriModal v-if="showTosModal" @close="showTosModal = false" maxWidth="650px">
      <template #body>
        <div>
          <p class="font-medium text-base text-primary flex mb-sm">
            {{ $t('profile.scheduledPayments.tos') }}
          </p>
          <span v-html="zoneDetails.termsOfSale" class="mb-sm"></span>
        </div>
      </template>
    </AriModal>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ProductsControl from '@/modules/products/mixins/productsControl.js';
import DeciTitle from '@/modules/common/generic/DeciTitle.vue';
import ModalManageWallet from '@/core/components/ModalManageWallet.vue';

export default {
  name: 'MyScheduledPayments',
  components: {
    DeciTitle,
    ModalManageWallet
  },
  mixins: [ProductsControl],
  mounted () {
    try {
      this.preparePageData();
      this.getZoneDetails();
    } catch (error) {
      this.$information.capture({ error, inform: true });
    }
  },
  data: function () {
    return {
      selectedUnpaidScheduledPayments: [],
      showModal: false,
      showModalWallet: false,
      showTosModal: false,
      zoneDetails: null,
      paymentIsLoading: false
    };
  },
  computed: {
    ...mapState('user', ['unpaidScheduledPayments']),
    ...mapGetters('user', ['upcomingScheduledPayments', 'defaultCard', 'usersZoneDetails']),
    ...mapGetters('config', ['currency']),
    preparedScheduledPayments () {
      const upcomingScheduledPayments = [...this.upcomingScheduledPayments];
      return this.prepareScheduledPayments(upcomingScheduledPayments);
    },
    preparedUnpaidScheduledPayments () {
      const unpaidScheduledPayments = [...this.unpaidScheduledPayments];
      return this.prepareScheduledPayments(unpaidScheduledPayments);
    },
    noPaymentFound () {
      return !this.preparedScheduledPayments?.length && !this.preparedUnpaidScheduledPayments?.length;
    },
    selectedUnpaidPrice () {
      let payments = [];
      if (this.preparedUnpaidScheduledPayments.length) {
        if (this.selectedUnpaidScheduledPayments.length) {
          payments = this.selectedUnpaidScheduledPayments;
        } else {
          payments = this.preparedUnpaidScheduledPayments;
        }
      }
      return payments.reduce(
        (previousValue, currentValue) => previousValue + +currentValue.originalPrice,
        0
      ).toFixed(2);
    }
  },
  methods: {
    ...mapActions('user', ['loadUserScheduledPayments', 'loadUnpaidScheduledPayments', 'loadUserWallet']),
    async preparePageData () {
      return Promise.allSettled([
        this.loadUserScheduledPayments(),
        this.loadUnpaidScheduledPayments(),
        this.loadUserWallet()
      ]).then(() => {
        window.scroll(0, 0);
      });
    },
    prepareScheduledPayments (schedules) {
      const renewalText = this.$t('product.isRenewal');
      return schedules.map(scheduledPayment => {
        const returnedSchedule = { ...scheduledPayment };
        const preparedPrice = this.preparePricePresentation(scheduledPayment.price);
        returnedSchedule.date = this.$datetime.getLocalFullDate(this.$datetime.fromISODate(scheduledPayment.date));
        returnedSchedule.productName = scheduledPayment.product.name;
        returnedSchedule.price = `${preparedPrice.price} ${preparedPrice.currency}`;
        returnedSchedule.originalPrice = scheduledPayment.price;
        returnedSchedule.renewal = scheduledPayment.renewal ? `(${renewalText})` : '';
        return returnedSchedule;
      });
    },
    handleSelectionChange (val) {
      this.selectedUnpaidScheduledPayments = val;
    },
    async onPayUnpaidSchedules () {
      if (!this.paymentIsLoading) {
        this.paymentIsLoading = true;
        try {
          await this.payUnpaidSchedules();
          this.$information.alert({
            message: this.$t('profile.scheduledPayments.schedulesPaid'),
            type: this.$CONSTANTS.USER_MESSAGE_TYPES.GREEN_SUCCESS
          });
          this.preparePageData();
        } catch (error) {
          this.$information.alert({
            message: this.$t('profile.scheduledPayments.errors.unpaidSchedulePaymentFailed')
          });
        } finally {
          this.paymentIsLoading = false;
          this.showModal = false;
        }
      }
    },
    async payUnpaidSchedules () {
      let selectedPayments = [];
      if (this.selectedUnpaidScheduledPayments.length) {
        selectedPayments = this.selectedUnpaidScheduledPayments;
      } else {
        selectedPayments = this.unpaidScheduledPayments;
      }

      const paymentScheduleIds = selectedPayments.map(payment => payment.id);

      await this.$services.HTTP.scheduledPayments.payUnpaidSchedules({
        paymentScheduleIds,
        paymentCardId: this.defaultCard.cardInd,
        useCredit: false
      });
    },
    async getZoneDetails () {
      try {
        const { data } =
          await this.$services.HTTP.zone.getZoneInfoFromMembersApi(
            this.usersZoneDetails.id
          );
        this.zoneDetails = data;
      } catch (e) {
        this.$information.alert({
          message: this.$t('products.noTosFound')
        });
      }
    },
    formatPaymentType (event) {
      return this.$t('paymentMode.' + event);
    }
  }
};
</script>

<style lang="less">
@import '../../../assets/styles/vars.less';

.my-scheduled-payments {
  .my-scheduled-payments__payments-container--unpaid {
    text-align: center;

    .deci-button {
    @apply mx-auto my-xxs;
      max-width: 280px;
    }

    .el-table {
      color: red;
    }
  }

  .my-scheduled-payments__payments-container {
    div {
      font-size: @small-text-size;
      word-break: break-word;
    }
  }
}
.loader-container {
  height: 22px;
}

@media (min-width: 992px) {
  .my-scheduled-payments {
    max-width: 992px;
  }
}
</style>
