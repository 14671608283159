<template>
  <div class="sign-in justify-center w-6/12">
    <ModalRecoverAccount
      :visibility="showRecoverAccountModal"
      :email="ruleForm.email"
      @on-cancel="showRecoverAccountModal=false"
      @on-reset="signInAfterReset"
    />
    <ModalDomainAccountVerification
      :visibility="showDomainAccountVerificationModal"
      :purpose="$t('tunnel.domainAccountVerification.onlyGlobalSignIn')"
      @redirectToSignUp="showDomainAccountVerificationModal=false"
    />
    <div class="flex justify-center flex-col">
      <div class="form-buttons-container">
        <AriForm ref="formSignIn" class="form-auth-signin" @action="onSignIn">
          <template v-slot="form">
            <div class="form-item">
              <span>{{ $t('email') }}</span>
              <ariInput whiteBg v-model="ruleForm.email" :form="form" :formatter="[required, mail]" />
            </div>
            <div class="form-item">
              <span>{{ $t('authenticate.password') }}</span>
              <ariInput whiteBg password v-model="ruleForm.password" :form="form" :formatter="[required]" />
            </div>
          </template>
        </AriForm>
      <AriButton id="forgottenPassword" class="item__forgot-password mb-default self-end" text
            @click="onRecoverAccount" :content="$t('authenticate.forgottenPassword')" />
      <AriButton id="signIn" class="w-full mb-default" filled :content="$t('authenticate.signIn')"
                 @click="onSignIn"
      />
      <AriButton id="signUp" outlined class="w-full" :content=" $t('authenticate.signUp')"
                 @click="redirectToSignUp"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ModalRecoverAccount from './ModalRecoverAccount.vue';
import ModalDomainAccountVerification from './ModalDomainAccountVerification.vue';
import AuthControl from '../mixins/authControl.js';
import { mail, required } from '@/utils/inputFormatters';

export default {
  name: 'SignIn',
  components: {
    ModalRecoverAccount,
    ModalDomainAccountVerification
  },
  mixins: [AuthControl],
  mounted () {
    window.scroll(0, 0);
    this.preAuth();
  },
  data () {
    return {
      showRecoverAccountModal: false,
      showDomainAccountVerificationModal: false,
      ruleForm: {
        email: '',
        password: ''
      },
      rules: {
        email: [
          {
            required: true,
            message: this.$t('authenticate.pleaseInputEmail'),
            trigger: 'change'
          },
          {
            required: true,
            message: this.$t('authenticate.pleaseInputEmail'),
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: this.$t('authenticate.pleaseInputPassword'),
            trigger: 'change'
          },
          {
            required: true,
            message: this.$t('authenticate.pleaseInputPassword'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    mail,
    required,
    ...mapActions('user', ['signInGlobal', 'signInClub']),
    redirectToSignUp () {
      if (this.goToRouteReminder) {
        this.$router.push({
          name: 'signUp',
          query: this.$router.currentRoute.value.query
        });
      } else {
        this.$router.push({ name: 'signUp', query: {} });
      }
    },
    async onSignIn () {
      try {
        const isFormValid = this.$refs.formSignIn.validateForm();
        // const isFormValid = await this.$refs.ruleForm.validate();
        if (!isFormValid) {
          throw new Error();
        }
        const { globalAccountAlreadyLinked, globalSignInSuccess } = await this.attemptGlobalSignIn();
        const domainSignInIsSuccessful = await this.attemptDomainSignIn();
        const onlyGlobalSignInSuccessfullAndNoAssociationFound =
          !domainSignInIsSuccessful &&
          (globalSignInSuccess && !globalAccountAlreadyLinked);
        const onlyGlobalSignInSuccessfullWithAssociation =
          !domainSignInIsSuccessful &&
          globalSignInSuccess && globalAccountAlreadyLinked;
        if (onlyGlobalSignInSuccessfullAndNoAssociationFound) {
          this.showDomainAccountVerificationModal = true;
        } else if (onlyGlobalSignInSuccessfullWithAssociation) {
          await this.postAuth();
        } else if (!domainSignInIsSuccessful && !globalSignInSuccess) {
          throw new Error();
        }
      } catch (error) {
        this.$information.alert({
          message: this.$t('authenticate.pleaseCheckCredentials'),
          duration: this.$CONSTANTS.USER_MESSAGE_DURATIONS.NORMAL
        });
        this.$information.capture({ error });
      }
    },
    async attemptGlobalSignIn () {
      let globalAccountAlreadyLinked = false;
      let globalSignInSuccess = false;
      try {
        const alreadyMemberAtDomain = await this.signInGlobal(this.ruleForm);
        globalAccountAlreadyLinked = alreadyMemberAtDomain;
        globalSignInSuccess = true;
      } catch (error) {
        this.$information.capture({ error });
      }
      return {
        globalAccountAlreadyLinked,
        globalSignInSuccess
      };
    },
    async attemptDomainSignIn () {
      try {
        await this.signInClub(this.ruleForm);
        await this.postAuth();
        return true;
      } catch (error) {
        this.$information.capture({ error });
        return false;
      }
    },
    onRecoverAccount () {
      this.showRecoverAccountModal = true;
    },
    async signInAfterReset ({ password, email }) {
      this.showRecoverAccountModal = false;
      this.ruleForm.password = password;
      this.ruleForm.email = email;
      await this.onSignIn();
    },
    checkForPreviousSocialLogin (error) {
      let isPreviousSocialLogin = false;
      if (!error.response || !error.response.data) {
        return isPreviousSocialLogin;
      }
      if (error.response.data.message === 'deciplus-member-login-google-required') {
        isPreviousSocialLogin = true;
        this.$information.capture({ error });
        this.$information.alert({
          message: this.$t('authenticate.pleaseLoginWithGoogle'),
          duration: this.$CONSTANTS.USER_MESSAGE_DURATIONS.NORMAL
        });
      } else if (error.response.data.message === 'deciplus-member-login-facebook-required') {
        isPreviousSocialLogin = true;
        this.$information.capture({ error });
        this.$information.alert({
          message: this.$t('authenticate.pleaseLoginWithFacebook'),
          duration: this.$CONSTANTS.USER_MESSAGE_DURATIONS.NORMAL
        });
      }
      return isPreviousSocialLogin;
    }
  }
};
</script>

<style lang="less">
.form-buttons-container {
  @apply flex flex-col w-full;
}
.form-auth-signin{
  @apply gap-lg flex flex-col;
}
</style>
