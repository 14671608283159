<template>
  <div
    class="my-payment-methods"
  >
    <ModalManageWallet :visibility="showManageWallet" @on-close="showManageWallet=false" />
    <div
      class="my-profile__section"
    >
      <div
        class="my-payment-methods__payment-method"
      >
        <AriButton
          filled
          :content="$t('profile.paymentMethods.manageCards')"
          @click="showManageWallet=true"
        />
      </div>
      <DeciTitle
        :text="$t('account.balance.title')"
        :isLined="false"
        size="small"
      />
      <p>{{ $t('account.balance.explanation1') }}</p>
      <p>{{ $t('account.balance.explanation2') }}</p>
      <br />
      <div
        class="my-payment-methods__payment-method"
      >
        <AriButton
          filled
          :content="$t('profile.paymentMethods.manageBalance')"
          @click="onClickManageBalance"
        />
      </div>
    </div>
    <div
      class="my-profile__section payments_section"
    >
      <div
        class="my-profile__half_section"
      >
        <DeciTitle
          :text="$t('profile.scheduledPayments.title')"
          :isLined="false"
          size="medium"
        />
        <AriButton
          filled
          neutralGrey
          :content="$t('profile.scheduledPayments.action')"
          @click="onOpenScheduledPayments"
        />
      </div>
      <div
        class="my-profile__half_section"
      >
        <DeciTitle
          :text="$t('profile.invoices.title')"
          :isLined="false"
          size="medium"
        />
        <AriButton
          filled
          neutralGrey
          :content="$t('profile.invoices.action')"
          @click="onOpenInvoices"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProductsControl from '@/modules/products/mixins/productsControl.js';
import DeciTitle from '@/modules/common/generic/DeciTitle.vue';
import ModalManageWallet from '@/core/components/ModalManageWallet.vue';

export default {
  name: 'MyPaymentMethods',

  mixins: [ProductsControl],

  components: {
    DeciTitle,
    ModalManageWallet
  },

  data () {
    return {
      showManageWallet: false
    };
  },

  computed: {

  },

  methods: {
    async onClickManageWallet () {
      try {
        await this.registerWallet();
      } catch (error) {
        this.$information.capture({
          error,
          inform: true,
          component: 'MyPaymentMethods',
          method: 'onClickManageWallet'
        });
      }
    },
    onClickManageBalance () {
      this.$router.push({ name: 'manageBalance' });
    },
    onOpenScheduledPayments () {
      this.$router.push({ name: 'scheduledPayments' });
    },
    onOpenInvoices () {
      this.$router.push({ name: 'invoices' });
    }
  }
};
</script>

<style lang="less">
.my-payment-methods{
  .my-payment-methods__payment-method{
    padding-right: 1rem;
    padding-bottom: 1rem;
  }
  .my-profile__section{
     &.payments_section{
       display: flex;
       flex-direction: column;
       justify-content: space-between;
       margin-bottom: 1rem;
       margin-top: 1rem;
       padding: 0 1rem 0 0;
       h2{
         margin-bottom: 1rem;
       }
     }
  }
}
@media (min-width: 500px){
  .my-payment-methods{
    text-align: initial;
    .my-payment-methods__payment-method{
      display: inline-block;
    }
    .my-profile__section{
      &.payments_section{
       flex-direction: row;
      }
      .my-profile__half_section{
        margin-right: 1rem;
      }
    }
  }
}
@media (min-width: 992px){
  .my-payment-methods{
    .my-profile__section{
      &.payments_section{
       width: 70%;
      }
    }
  }
}
</style>
