<template>
  <header class="el-header">
    <div class="main-nav-content">
      <div class="el-header__header-elements-container items-center" :class="!shouldShowLogo ? 'forceFlexEnd' : null">
        <div v-if="shouldShowLogo" class="el-image logo" @click="goToHome()">
          <img src="../../assets/images/xplor_deciplus_stacked_positive_RGB.png"
               class="el-image__inner" style="object-fit: fill;"
               :title="$t('goToHome')" :alt="$t('goToHome')"
          />
        </div>
        <div class="grid-content el-header__menu_items" v-click-outside="closeHamburgerMenu">
          <interactive-div
            @on-interaction="toggleHamburgerMenu()"
            class="header__mobile_hamburger"
            id="toggle"
          >
            <div class="span" id="top" :class="{ active: expanded }"></div>
            <div class="span" id="middle" :class="{ active: expanded }"></div>
            <div class="span" id="bottom" :class="{ active: expanded }"></div>
          </interactive-div>
          <div
            @select="toggleHamburgerMenu()"
            class="menu-member-web"
            :class="{ 'menu-mobile-expanded': expanded }"
            :mode="expanded ? 'vertical' : 'horizontal'"
            default-active="">
            <span index="0">
              <router-link
                :class="this.isHomeRoute ? 'router-link-active' : null"
                :to="homeRoute"
              >
                {{ $t("menu.home") }}
              </router-link>
            </span>
            <span index="1" v-if="showLandingPage">
              <router-link
                exact
                :to="landingRoute"
              >
                {{ $t("menu.landing") }}
              </router-link>
            </span>
            <template v-if="customMenus && customMenus?.length">
              <span @click="openCalendar">
                <router-link class="whitespace-nowrap m-auto" v-if="customMenuIndexUsed === null" :to="calendarRoute" >
                  <span class="cursor-pointer" >{{ $t("calendar.timeslot.create") }}</span>
                </router-link>
                <div v-else class="cursor-pointer"> {{ $t("calendar.timeslot.create") }}</div>
              </span>
              <template v-if="isCustomMenuInHeader">
                <span v-for="(customMenu, index) in customMenus"
                   @click="useCustomMenu(customMenu, index)"
                   :key="index" class="cursor-pointer"
                   :class="{'router-link-exact-active': customMenuIndexUsed === index}">
                {{capitalizeFirstLetter(customMenu.name)}}
              </span>
              </template>
            </template>
            <span index="2" v-if="!memberAppConfigs.LDC_noPlanning && (!customMenus || !customMenus?.length)">
              <router-link
                :to="calendarRoute"
              >
                {{ $t("menu.calendar") }}
              </router-link>
            </span>
            <span index="3" v-if="memberAppConfigs.availableVideosCount">
              <router-link
                :to="videoRoute"
              >
                {{ $t("videoGallery.title") }}
              </router-link>
            </span>
            <span index="4">
              <router-link :to="addCreditRoute">
                {{ $t("menu.addCredit") }}
              </router-link>
            </span>
            <span v-if="loggedInUser" class="myProfile" index="5">
              <router-link class="myProfile" exact :to="myProfileRoute">
                {{ $t("menu.profile") }}
              </router-link>
            </span>
            <span v-if="loggedInUser" class="myProfile" index="6">
              <router-link class="myProfile" :to="manageBalanceRoute">
                {{ $t("menu.profile.giftCard") }}
              </router-link>
            </span>
            <mq-responsive target="lg+" v-if="loggedInUser" class="items-center flex">
              <span class="flex items-center profile">
                <el-popover placement="bottom-start" :width="300" :show-arrow="false" popper-class="popper-account"	>
                  <template #reference>
                    <UserAvatar class="user-avatar" />
                  </template>
                  <div class="container-popper">
                    <div class="flex justify-center items-center flex-col">
                      <UserAvatar class="profile-image-pop-over" />
                      <div class="user-infos">
                        <div class="names">
                          <span class="first-name">{{loggedInUser.name}} </span>
                          <span class="last-name">{{loggedInUser.surname}}</span>
                        </div>
                        <span class="user-mail">{{loggedInUser.email}}</span>
                      </div>
                    </div>
                    <div class="account-actions">
                      <div @click="redirectToProfile()" class="account-action"><div>{{$t('menu.profile.seeMyProfile')}}</div></div>
                      <div @click="redirectToBalance()" class="account-action"><div>{{$t('menu.profile.giftCard')}}</div></div>
                      <div @click="onSignOut()" class="account-action log-out"><div>{{$t('logout')}}</div></div>
                    </div>
                  </div>
                </el-popover>
              </span>
            </mq-responsive>
            <span
              v-if="loggedInUser"
              @click="onSignOut()"
              class="flex logoutButton">
              <div class="flex gap-default items-center">
                <span class="sign-label-mobile">{{$t('menu.signOut')}}</span>
              </div>
            </span>
            <router-link v-if="!loggedInUser"
              :to="signInRoute"
              class="el-menu-item-signin"
            >
              <AriButton
                filled
                :content="$t('authenticate.signIn')"
                buttonType="strongFilled"
              />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { capitalizeFirstLetter } from '@/utils/common';
import { buildDeciLogoUrl } from '@/utils/url';
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import UserAvatar from '@/modules/common/UserAvatar.vue';
import Popover from '@/core/components/popover.vue';
import CalendarControl from '@/modules/calendar/mixins/calendarControl.js';
import mixinCustomMenu from '@/modules/common/Header/mixin.customMenu.vue';

export default {
  name: 'HeaderMemberWeb',
  components: { Popover, UserAvatar },
  data () {
    return {
      expanded: false,
      showPopover: false,
      showCustomMenusPopover: false,
      videosLoaded: false,
      isTunnelLoaded: false
    };
  },
  mixins: [CalendarControl, mixinCustomMenu],
  computed: {
    ...mapState('config', ['tunnelConfigurations']),
    ...mapGetters('domain', ['domain', 'getZoneLogoPath']),
    ...mapGetters('user', ['loggedInUser']),
    ...mapState('zone', ['videos']),
    ...mapGetters('config', ['cornerLogoRemovedByTunnel', 'showLogo', 'showLandingPage', 'customMenus', 'isCustomMenuInHeader']),
    ...mapState('user', ['deviceFormat']),
    ...mapState('config', ['memberAppConfigs']),
    ...mapState('domain', ['zones']),
    isOnMobile () {
      return this.deviceFormat === this.$CONSTANTS.DEVICE_FORMATS.MOBILE;
    },
    userName () {
      const { name = '', surname = '' } = this.loggedInUser || {};
      return `${name.toLowerCase()} ${surname.toLowerCase()}`;
    },
    homeRoute () {
      return `/${this.domain}/home`;
    },
    landingRoute () {
      return `/${this.domain}`;
    },
    calendarRoute () {
      return `/${this.domain}/calendar`;
    },
    addCreditRoute () {
      return `/${this.domain}/shop`;
    },
    signInRoute () {
      return `/${this.domain}/signIn`;
    },
    myProfileRoute () {
      return `/${this.domain}/me`;
    },
    manageBalanceRoute () {
      return `/${this.domain}/me/balance`;
    },
    videoRoute () {
      return `/${this.domain}/videos`;
    },
    logoUrl () {
      return buildDeciLogoUrl(this.getZoneLogoPath);
    },
    shouldShowLogo () {
      return !this.cornerLogoRemovedByTunnel && this.showLogo;
    },
    isHomeRoute () {
      return this.$route.path === this.homeRoute || this.$route.path === this.signInRoute;
    }
  },
  mounted ()  {
    this.loadVideos();
  },
  watch: {
    async tunnelConfigurations (newVal) {
      if (Object.keys(newVal)?.length && this.isCustomMenuInHeader && !this.isTunnelLoaded) {
        const tunnelCMname = this.tunnelConfigurations[this.$CONSTANTS.TUNNEL_LABELS.CUSTOM_MENU];
        if (tunnelCMname) {
          const filter = this.customMenus.find(cm => cm.name === tunnelCMname);
          if (filter) await this.useCustomMenu(filter, this.customMenus.findIndex(cm => cm.name === tunnelCMname));
        }
        this.isTunnelLoaded = true;
      }
    },
    '$route' (from, to) {
      this.expanded = false;
      if (to.name === 'calendar') {
        this.customMenuIndexUsed = null;
      }
      if(to.name === 'signIn' && !this.videosLoaded) {
        this.loadVideos();
        this.videosLoaded = true;
      }
    }
  },
  methods: {
    capitalizeFirstLetter,
    ...mapActions('zone', ['loadVideos']),
    ...mapActions('user', ['logout']),
    ...mapActions('config', ['_detectCalendarModeConfiguration']),
    ...mapMutations('config', ['SET_SELECTED_ACTIVITIES', 'SET_SELECTED_RESOURCES', 'SET_SELECTED_COACHES', 'SET_SELECTED_ZONE', 'SET_SELECTED_CALENDAR_MODE']),
    ...mapMutations('cart', ['RESET_CART']),
    ...mapActions('zone', [
      'loadAllResources',
      'loadAllActivities',
      'loadAllCoaches'
    ]),
    openPopover () {
      this.showPopover = true;
      this.showCustomMenusPopover = false;
    },
    openCustomMenusPopover () {
      this.showCustomMenusPopover = true;
      this.showPopover = false;
    },
    closePopover () {
      setTimeout(() => {
        this.showPopover = false;
        this.showCustomMenusPopover = false;
      }, 50);
    },
    toggleHamburgerMenu () {
      this.expanded = !this.expanded;
    },
    closeHamburgerMenu () {
      this.expanded = false;
    },
    async onSignOut () {
      try {
        this.RESET_CART();
        this.closePopover();
        await this.logout();
        this.$information.alert({
          message: this.$t('authenticate.loggedOut'),
          type: this.$CONSTANTS.USER_MESSAGE_TYPES.GREEN_SUCCESS
        });
        this.$router.replace({ name: 'signIn' });
      } catch (error) {
        this.$information.capture({ error });
      }
    },
    restartLanding () {
      this.$router.replace({
        name: 'landing'
      });
    },
    goToHome () {
      this.$router.replace({
        name: 'home'
      });
    },
    redirectToProfile () {
      this.closePopover();
      this.$router.replace({
        name: 'profile'
      });
    },
    redirectToBalance () {
      this.closePopover();
      this.$router.replace({
        name: 'manageBalance'
      });
    },
    async openCalendar () {
      if (this.$route.name !== 'calendar') {
        await this.$router.push({ name: 'calendar' });
      }
      this.showCustomMenusPopover = false;
      this.customMenuIndexUsed = null;
      this.SET_SELECTED_ACTIVITIES([]);
      this.SET_SELECTED_RESOURCES([]);
      this.SET_SELECTED_COACHES([]);
      if (!this.memberAppConfigs.LDC_web_showResourcesFirst) {
        this.SET_SELECTED_CALENDAR_MODE(this.$CONSTANTS.CALENDAR_MODES.SESSION);
      }
      await this._detectCalendarModeConfiguration({ LDC_web_showResourcesFirst: this.memberAppConfigs?.LDC_web_showResourcesFirst, webConfig_calendarMode: this.memberAppConfigs?.webConfig_calendarMode });
      await this.getAllTimeslotsFromStore();
      window.scroll(0, 0);
      this.toggleHamburgerMenu();
    },
    async loadZoneDetailsFromStore () {
      try {
        await this.loadAllActivities();
        await this.loadAllResources();
        await this.loadAllCoaches();
      } catch (err) {
        this.$information.capture({ err });
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import '../../assets/styles/vars.less';
@import '../../assets/styles/mixins.less';

// GEstion menu & item du menu
.menu-member-web {
  @apply flex gap-default items-center px-lg;
}
.menu-member-web > span {
  @apply px-default;
}
@media (max-width: 1023px) {
  .menu-member-web {
    display: none;
  }
  .menu-member-web.menu-mobile-expanded {
    max-height: 100%;
    min-height: 100%;
    @apply flex flex-col top-0 left-0 absolute w-full bg-content gap-lg py-lg overflow-auto;
  }
}
.is-active {
  color: var(--ariane-primary-500) !important;
}
.router-link-exact-active {
  color: var(--ariane-primary-500) !important;
}

// LEGACY
.profile-image-pop-over {
  width: 70px;
  height: 70px;
  border-radius: 1000px;
  border: 1px solid var(--ariane-border);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition-duration: 0.3s !important;
  @apply m-sm;
}

.user-infos {
  @apply flex flex-col items-center gap-xs;
}
.names {
  @apply flex gap-xs text-base font-medium text-primary;
}
.user-mail {
  @apply text-secondary;
}
.account-action {
  width: 100%;
  transition-duration: 0.3s !important;;
  text-align: left;
  padding: var(--ariane-s) var(--ariane-big-base);
}
.account-action:hover {
  background-color: var(--ariane-border);
  cursor: pointer;
}
.account-action-selected {
  background-color: var(--ariane-border);
  padding: var(--ariane-s) var(--ariane-medium);
}
.log-out:hover {
  color: var(--ariane-accent-text-error);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s !important;;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.sign-label-mobile {
  display: flex;
}

.forceFlexEnd {
  justify-content: flex-end !important;
}

.el-menu-item-signin {
  display: flex;
  align-items: center;
}
.user-avatar {
  display: none;
}

.el-header{
  display: flex;
  flex-direction: column;
  box-shadow:  0 1px 0 #EBEBEB;
  padding: 0;
  height:@header-footer-height;
  z-index: 3;
  width: 100%;
  background: white;
  .main-nav-content{
    display: flex;
    height: 100%;
    justify-content: space-between;
    .el-header__header-elements-container{
      display: flex;
      justify-content: space-between;
      width: 100%;
      .el-image{
        overflow: initial;
        display: none;
        &.logo{
          position: absolute;
          right: 20px;
          top: 0px;
          cursor: pointer;
          vertical-align: middle;
        }
        img{
          padding: 6px;
          max-height: 65px;
          width: auto;
        }
      }
    }
    .header__mobile_hamburger {
      display: block;
      opacity: 1;
      position: absolute;
      left: 20px;
      top: 10px;
      z-index: 100;
      width: 45px;
      height: 40px;
      padding-right: 20px;
      padding-bottom: 10px;
      cursor: pointer;
      transition: all .3s ease-out !important;;
      margin-top: 6px;
      .span {
        border-radius: 10px;
        background: black;
        transition: all 0.3s ease-out !important;;
        backface-visibility: hidden;
        height: 4px;
        margin: 5px 0;
        transition: all .3s ease-out !important;;
        backface-visibility: visible;
        display: block;
        opacity: 1;
      }

      #top.span.active {
        transform: rotate(45deg) translateX(3px) translateY(5px);
      }

      #middle.span.active {
        opacity: 0;
      }

      #bottom.span.active {
        transform: rotate(-45deg) translateX(8px) translateY(-10px);
      }
    }
  }
}

@media (min-width: 1024px){
  .logoutButton {
    display: none !important;
  }
  .myProfile {
    display: none;
  }
  .user-avatar {
    display: flex !important;
    object-fit: cover !important;
    height: 32px !important;
    width: 32px !important;
  }
  .sign-label-mobile {
    display: none;
  }
  .el-header{
    .main-nav-content{
      .el-header__header-elements-container{
        .el-image{
          display: inline-block;
          &.logo{
            position: relative;
            right: 0;
          }
        }
      }
    }
  }
}
@media (min-width: 1024px){
  .header__mobile_hamburger {
    display: none !important;
  }
}
.container-popper {
  width: fit-content;
  @apply flex flex-col w-full rounded-md;
}

</style>
