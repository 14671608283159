<template>
  <div class="w-400 white-card summary-container">
    <span id="summary-title" class="py-xxs categorie-title text-primary font-medium">
      {{ $t('cart.summary') }}
    </span>
    <div>
      <div class="flex justify-between cursor-pointer px-sm md:px-0" @click="promotionOpen = !promotionOpen">
        <span>{{ $t('cart.promotion.message') }}</span>
        <i class="fa" :class="promotionOpen ? 'fa-chevron-up'  : 'fa-chevron-down'"/>
      </div>
      <div class="flex gap-sm h-fc" v-if="promotionOpen">
        <AriInput :placeholder="$t('products.promoCode')" ref="inputPromoCode" :modelValue="promoCode" @update:modelValue="checkPromoCode($event)" class="flex-grow flex"/>
        <i class="fas fa-check icon-promo-code" v-if="isPromoValid" />
      </div>
      <div v-for="(promo, index) in activePromos" :key="index" class="flex justify-between items-center text-xs pt-sm">
        <span class="text-secondary">{{promo.code}}</span>
        <i class="fa fa-trash px-xxs cursor-pointer hover:opacity-30" @click="deletePromo(promo)"/>
      </div>
    </div>
    <div class="divider-h"/>
    <div class="p-xs text-center font-extralight">
      <div class="flex flex-col py-md">
        <template v-if="hasPromo" >
          <div class="flex justify-between">
            <span>{{$t('shop.directPaiement')}}</span>
            <span>{{formatPrice(regularTotalPrice)}} {{ currency }}</span>
          </div>
          <div class="flex justify-between">
            <span>{{$t('shop.remise')}}</span>
            <span class="text-error">-{{formatPrice(regularTotalPrice - totalPrice)}} {{ currency }}</span>
          </div>
        </template>
        <div class="flex justify-between">
          <span>{{ useCredit ? $t('cart') : (activePromos.length > 0 ? $t('shop.toPayNow'): $t('cart.total')) }}</span>
          <span>{{ totalPrice }} {{ currency }}</span>
        </div>
      </div>
      <template v-if="useCredit">
        <div class="flex justify-between">
          <span>{{ $t('cart.pot') }}</span>
          <span class="text-valid">-{{ formatPrice(Math.min(userBalance.balance, totalPrice)) }} {{ currency }}</span>
        </div>
        <div class="flex justify-between mt-xs">
          <span>{{ $t('cart.total') }}</span>
          <span>{{ totalPrice - userBalance.balance >= 0 ? formatPrice(totalPrice - userBalance.balance) : 0 }} {{ currency }}</span>
        </div>
      </template>
    </div>
    <div>
      <ari-checkbox v-if="showUserBalance" v-model="useCredit" class="w-fit text-center mb-xs">
        <span class="text-xs">
          {{ $t('cart.use.pot', { amount: balance }) }}
        </span>
      </ari-checkbox>
    </div>

    <div class="flex flex-col gap-default">
      <div class="flex flex-col gap-xs">
        <AriButton class="mt-auto w-full"
          :preIcon="initiatePayment ? 'filled fa fa-spinner fa-spin' : null"
          :content="$t('cart.pay')"
          filled
          :disabled="!isCartInStock || !allowPaymentData"
          @click="onBuy"
        />
        <div class="text-center text-xs font-extralight">
          <div v-if="hasZoneTos">
            <span>{{ $t("products.acceptTos") }}</span>
            <span class="link" @click="isTOSShown = true"> {{usersZoneDetails.clubName}}</span>
          </div>
          <div v-else class="text-error">{{ $t("products.noTosFound") }}</div>
        </div>
        <div v-if="forceToRegisterWallet" class="cart-payment-message">
          {{ $t("products.registerWallet") }}
          <AriButton
            text
            :content="$t('profile.paymentMethods.manageCards')"
            @click="showManageWallet = true"
          />
        </div>
      </div>
    </div>
    <HtmlViewerModal
      :visibility="isTOSShown"
      :dangerousHtmlContent="zoneInfoForProduct.termsOfSale"
      @on-cancel="isTOSShown = false"
    />
    <ModalManageWallet :visibility="showManageWallet" @on-close="showManageWallet=false" />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import HtmlViewerModal from '@/modules/common/HtmlViewerModal.vue';
import ProductsControl from '@/modules/products/mixins/productsControl.js';
import ModalManageWallet from '@/core/components/ModalManageWallet.vue';

export default {
  name: 'cart-summary',
  mixins: [ProductsControl],
  components: {
    HtmlViewerModal,
    ModalManageWallet
  },
  data () {
    return {
      isTOSShown: false,
      promoCode: null,
      isPromoValid: null,
      promotionOpen: false,
      useCredit: false,
      allowPaymentData: true,
      activePromos: [],
      showManageWallet: false,
    };
  },
  mounted () {
    this.allowPayment();
    this.getActivePromos();
  },
  computed: {
    ...mapGetters('config', ['currency']),
    ...mapGetters('cart', [
      'totalPrice',
      'regularTotalPrice',
      'isStockAvailable',
      'hasCashPaymentNotDefined',
      'hasOneScheduledPayment',
      'hasZoneTos',
      'hasGift'
    ]),
    ...mapGetters('user', ['usersZoneDetails', 'defaultCard']),
    ...mapState('cart', ['initiatePayment', 'zoneInfoForProduct', 'soldOutProducts', 'products']),
    ...mapState('user', ['userBalance']),
    ...mapState('config', ['memberAppConfigs']),
    forceToRegisterWallet() {
      return (!this.memberAppConfigs?.LDC_defautOnlineSchedulePaymentType || this.memberAppConfigs?.LDC_defautOnlineSchedulePaymentType === 'ECB') && (this.hasOneScheduledPayment && !this.defaultCard);
    },
    showUserBalance () {
      return this.userBalance && this.userBalance.balance > 0 && !this.hasGift;
    },
    hasPromo () {
      return this.activePromos.some(p => p.promotion && parseFloat(p.promotion.price) > 0);
    },
    balance () {
      return this.userBalance ? this.userBalance?.balance + this.currency : '-';
    },
    isCartInStock () {
      if (!this.soldOutProducts) return true;
      else {
        let cartInStock = true;
        Object.keys(this.products).forEach(key => {
          const productInCart = this.products[key];
          if (this.soldOutProducts.includes(productInCart.config.id) && !this.isStockAvailable(productInCart.config.id)) {
            cartInStock = false;
          }
        });
        return cartInStock;
      };
    }
  },
  watch: {
    products () {
      this.allowPayment();
      this.getActivePromos();
    }
  },
  methods: {
    ...mapActions('user', ['loadUserWallet']),
    ...mapMutations('cart', ['SET_INITIATE_PAYMENT', 'ADD_PRODUCT_TO_CART', 'SET_USE_CREDIT', 'DELETE_PROMO']),
    checkPromoCode (value) {
      this.promoCode = value;
      this.isPromoValid = false;
      this.$services.HTTP.product.getPromotionByCode(value).then(res => {
        this.ADD_PRODUCT_TO_CART({
          product: res.data[0],
          onlyScheduledPaymentsOnline: this.memberAppConfigs?.onlyScheduledPaymentsOnline
        });
        this.getActivePromos();
        this.isPromoValid = true;
        this.promoCode = '';
      }).catch(err => {
        this.isPromoValid = false;
        setTimeout(() => {
          const errCode = err.response.data.message;
          if (errCode === 'promotion-not-found-for-code') this.$refs.inputPromoCode.inputError = this.$t('products.promoCode.invalid');
          else if (errCode === 'promotion-from-another-zone') this.$refs.inputPromoCode.inputError = this.$t('products.promoCode.invalidForSite', { name: this.usersZoneDetails.clubName });
          else if (errCode === 'promotion-with-disabled-product') this.$refs.inputPromoCode.inputError = this.$t('products.promoCode.invalid');
          else if (errCode === 'promotion-fully-used') this.$refs.inputPromoCode.inputError = this.$t('products.promoCode.expired');
          else if (errCode === 'promotion-wrong-validity') this.$refs.inputPromoCode.inputError = this.$t('products.promoCode.expired');
          else if (errCode === 'promotion-not-available-for-this-product') this.$refs.inputPromoCode.inputError = this.$t('products.promoCode.invalid');
          else this.$refs.inputPromoCode.inputError = this.$t('products.promoCode.processingError');
        }, 50);
      });
    },
    getActivePromos () {
      const promos = [];
      Object.keys(this.products).forEach(p => {
        if (this.products[p].code) promos.push(this.products[p]);
      });
      this.activePromos = promos;
    },
    deletePromo (product) {
      this.DELETE_PROMO(product.config.id);
      this.getActivePromos();
    },
    async onBuy () {
      if (this.allowPaymentData && this.isCartInStock) {
        await this.loadUserWallet();
        if(!this.forceToRegisterWallet) {
          this.SET_USE_CREDIT(this.useCredit);
          this.SET_INITIATE_PAYMENT(true);
        }else {
          this.$ariAlert(this.$t('shop.noWalletRegisteredForPaymentSchedules'), this.$t('shop.noWalletRegisteredExplanation'), {
            showCancelButton: true,
            confirmButtonText: this.$t('profile.paymentMethods.manageWallet')
            }).then(result => {
              if(result.isConfirmed) {
                this.showManageWallet = true;
              }
          });
        }
      } else if (this.hasCashPaymentNotDefined) {
        this.$information.alert({
          showClose: true,
          message: this.$t('cart.error.choosePaymentMode'),
          duration: this.$CONSTANTS.USER_MESSAGE_DURATIONS.NORMAL
        });
      }
    },
    allowPayment () {
      this.allowPaymentData = this.hasZoneTos && !this.initiatePayment && this.isStockAvailable() && !this.hasCashPaymentNotDefined;
    },
    formatPrice (price) {
      return parseFloat((price).toFixed(10)).toFixed(2).toString().replace(/\.00$/, '');
    }
  }
};
</script>

<style lang="less" scoped>
.link {
  color:var(--ariane-primary-400);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.icon-promo-code {
  @apply bg-valid text-on-valid px-default py-xs rounded;
  height: 32px;
}
.summary-container {
  @apply gap-xxs p-sm lg:p-md rounded mt-xs flex flex-col;
}
.mt-auto {
  margin: 0;
  margin-top: auto;
}
.w-400 {
  min-width: 400px;
}
.disabled-btn {
  cursor: not-allowed;
  opacity: 0.4;
}
.divider-h {
  border: 1px solid;
  @apply h-0 w-full border-default;
}
.cart-payment-message {
  @apply flex flex-col text-center rounded text-attention-900 px-default py-xs bg-attention-50;
}
@media screen and (max-width: 980px) {
  .summary-container {
    #summary-title {
      display: none;
    }
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // margin: 0px;
    // padding: 20px;
    // z-index: 1;
  }
}
</style>
