import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { getOnlineShopLink, getVideoDetailsLink, getHomeLink } from '@/utils/url.js';
import browserStorage from '@/services/browserStorage';
import cartStorage from '@/services/browserStorage/cart';
import CONSTANTS from '@/utils/constants';

export default {
  computed: {
    ...mapState('domain', ['zones']),
    ...mapGetters('config', [
      'currency',
      'facebookTrackingId',
      'domainName',
      'forcedZoneByTunnel',
      'zoneShopFilterByTunnel',
      'prepaidCardSaleMode',
      'subscriptionSaleMode',
      'selectedZone',
      'zoneFilterByTunnel'
    ]),
    ...mapGetters('domain', ['isMultiSite', 'getActiveZone']),
    ...mapState('cart', ['pendingResa']),
    ...mapGetters('user', ['loggedInUser', 'usersZoneDetails']),
    ...mapState('user', ['userDomainInfo', 'deviceFormat']),
    isComingFromPurchaseAttempt () {
      return this.$router.currentRoute.value.query.openTimeslot || false;
    },
    returnURLForPurchase () {
      if (this.$route.query.to === 'video') {
        return getVideoDetailsLink(this.$route.query.id);
      } else if (this.pendingResa) return getHomeLink();
      else return getOnlineShopLink();
    },
    isOnMobile () {
      return this.deviceFormat === this.$CONSTANTS.DEVICE_FORMATS.MOBILE;
    },
    hasWebPayment () {
      if (this.loggedInUser) {
        return this.usersZoneDetails.hasWebPayment;
      }
      return this.selectedZone ? this.selectedZone.hasWebPayment : this.getActiveZone(this.zoneFilterByTunnel)?.hasWebPayment;
    },
    getShopZone () {
      if (this.loggedInUser) {
        return this.usersZoneDetails;
      }
      return this.selectedZone || this.getActiveZone(this.zoneFilterByTunnel);
    }
  },
  methods: {
    ...mapActions('zone', ['loadAllProducts', 'loadAllPublicProducts']),
    ...mapActions('user', ['loadUserBalance', 'saveProductForDetailsPage']),
    ...mapActions('domain', ['setAppState']),
    ...mapActions('cart', ['checkProductsStock']),
    ...mapMutations('cart', ['RESET_CART', 'SET_INITIATE_PAYMENT']),
    async prepareProducts () {
      if (!this.loggedInUser) {
        let filter;
        if (this.forcedZoneByTunnel) {
          filter = { zoneId: this.forcedZoneByTunnel };
        } else if (this.zoneShopFilterByTunnel) {
          filter = { zoneId: this.zoneShopFilterByTunnel };
        } else if (this.isMultiSite){
          filter = this.selectedZone || this.getActiveZone(this.zoneFilterByTunnel) ? { zoneId: this.selectedZone?.id || this.getActiveZone(this.zoneFilterByTunnel)?.id } : {};
        } else {
          filter = {};
        }
        return this.loadAllPublicProducts(filter);
      }
      await this.loadAllProducts();
    },
    validityDuration (product) {
      return `${product.validityLength} ${this.$tc('products.durations.' + product.validityUnit, product.validityLength)}`;
    },
    validityZone (product) {
      if (product.type === this.$CONSTANTS.PRODUCT_TYPES.GIFT_CARD) return null;
      if (this.loggedInUser || !product ) {
        return this.usersZoneDetails?.clubName;
      }
      if (!this.isMultiSite) {
        return this.domainName;
      }
      const productZone = this.zones.find(zone => zone.id === product.zone);
      return productZone?.clubName || this.$t('products.validEverywhere', { domainName: this.domainName });
    },
    productPricing (product, forcePeriod = false) {
      const isSubscription = product.type === this.$CONSTANTS.PRODUCT_TYPES.SUBSCRIPTION2;
      const isPrepaidCard = product.type === this.$CONSTANTS.PRODUCT_TYPES.PREPAID_CARD2 || product.type === this.$CONSTANTS.PRODUCT_TYPES.PREPAID_CARD3;
      if (isSubscription) {
        return this._getPricingForSubscription(product, forcePeriod);
      } else if (isPrepaidCard) {
        return this._getPricingForPrepaidCard(product, forcePeriod);
      } else {
        return this.preparePricePresentation(product.totalPrice);
      }
    },
    _getPricingForSubscription (product, forcePeriod) {
      if (forcePeriod && product.scheduler && product.scheduler[0]) {
        return this._findPricingFormatFromConfig(this.subscriptionSaleMode, product, forcePeriod);
      } else {
        return this.preparePricePresentation(product.totalPrice);
      }
    },
    _getPricingForPrepaidCard (product, forcePeriod) {
      if (forcePeriod && product.scheduler && product.scheduler[0]) {
        return this._findPricingFormatFromConfig(this.prepaidCardSaleMode, product, forcePeriod);
      } else {
        return this.preparePricePresentation(product.totalPrice);
      }
    },
    _findPricingFormatFromConfig (pricingConfig, product, forcePeriod) {
      if (forcePeriod && product.scheduler && product.scheduler[0]) {
        return this.preparePricePresentationPerPeriod(product.scheduler[0].price, product.schedulePeriodType, product.schedulePeriodUnit);
      }
      switch (pricingConfig) {
        case this.$CONSTANTS.PRODUCT_PRICING_FORMATS.INSTALLMENTS:
          return this.preparePricePresentationPerPeriod(product.scheduler[0].price, product.schedulePeriodType, product.schedulePeriodUnit);
        case this.$CONSTANTS.PRODUCT_PRICING_FORMATS.TOTAL:
        default:
          return this.preparePricePresentation(product.totalPrice);
      }
    },
    preparePricePresentation (price) {
      return { currency: this.currency, price: price.toString().replace(/\.00$/, '') };
    },
    preparePricePresentationPerPeriod (price, period, count) {
      return {
        price: price.toString().replace(/\.00$/, ''),
        currency: this.currency,
        period: this.$tc('products.durations.' + period, count)
      };
    },
    productOriginalPricing (product) {
      if (product.originalTotalPrice) {
        return this.preparePricePresentation(product.originalTotalPrice);
      }
    },
    onOpenBuyWindow (product) {
      this.trackViewProduct(product.name);
      this.saveProductForDetailsPage(undefined);
      if (!this.loggedInUser) {
        this.alertSignInMandatory();
        return this.redirectToSignIn(product.id);
      }
      if (this.isOnMobile) {
        this.redirectToProductDetailsPage(product.id);
      }
      this.saveProductForDetailsPage(product.id);
      browserStorage.setCurrentProduct(product.id);
    },
    trackViewProduct (productName) {
      if (this.facebookTrackingId) {
        this.$information.track.viewContentWithFacebook(productName);
      }
      this.$information.track.viewContentWithGoogle(productName);
    },
    onBuy ({ returnURL, products, useCredit }) {
      this.trackPurchase();
      return this.doPayment(returnURL, products, useCredit).then((paymentLink) => {
        if (paymentLink === this.$CONSTANTS.API_MESSAGES.TRANSACTION_ACCEPTED) {
          this.redirectToHome();
          setTimeout(() => { this.RESET_CART(); }, 500);
        } else {
          this.redirectToPayment(paymentLink);
        }
        if (useCredit) {
          return this.loadUserBalance();
        }
      }).catch((err) => {
        this.SET_INITIATE_PAYMENT(false);
        this.$information.alert({
          message: err.response?.data?.message === CONSTANTS.ERRORS.ORDER.CASH_PAYMENT_NOT_AVAILABLE ? this.$t('cart.error.cashPaymentNotAvailable') : this.$t('cart.error.noStock')
        });
      });
    },
    trackPurchase () {
      if (this.facebookTrackingId) {
        this.$information.track.startPurchaseWithFacebook();
      }
      this.$information.track.startPurchaseWithGoogle();
    },
    async doPayment (returnURL, products, useCredit) {
      const body = { products };
      if (this.pendingResa?.resaDatas) {
        const bodyResaDatas = this.pendingResa?.resaDatas;
        if (bodyResaDatas.bookingId) {
          body.booking = {};
          body.booking.placeId = bodyResaDatas.placeId;
          body.booking.id = bodyResaDatas.bookingId;
          body.booking.invitedMembers = bodyResaDatas.invitedMembers;
        } else {
          body.resource = bodyResaDatas.schedule;
          body.resource.invitedMembers = bodyResaDatas.invitedMembers;
        };
      }
      let cartId = null;
      try {
        cartId = await this.$services.HTTP.product.addProductToCart(body);
        return this.$services.HTTP.product.processCart(cartId, returnURL, useCredit);
      } catch (err) {
        if (err.response?.data?.data?.soldOutProducts) {
          this.checkProductsStock();
        }
        throw err;
      }
    },
    rearrangeUrlAfterTransaction () {
      // Edit query to clean URL + return if the url will change
      const currentURL = window.location.toString();
      const maybeDomain = new URL(window.location.href).pathname.split('/')[1];
      const domain = maybeDomain || new URL(window.location.href).hash.split('/')[1];
      if (currentURL.includes('cancelled')) {
        window.location.href = `${window.location.origin}/${domain}/home?isCancel=true`;
        return true;
      } else if (currentURL.includes('success')) {
        window.location.href = `${window.location.origin}/${domain}/home?isSuccess=true`;
        cartStorage.clear();
        return true;
      } else return false;
    },
    reactToTransactionResponse () {
      let information;
      if (this.$route.query.isCancel) {
        information = {
          message: this.$t('products.purchaseCancelled')
        };
        browserStorage.setCurrentProduct(undefined);
      } else if (this.$route.query.isSuccess) {
        this.trackPurchaseCompleted();
        information = {
          message: this.$t('products.purchaseSuccessful'),
          type: this.$CONSTANTS.USER_MESSAGE_TYPES.GREEN_SUCCESS
        };
        browserStorage.setCurrentProduct(undefined);
      }
      if (information) {
        this.showTransactionMessage(information);
        // this.$router.replace({ 'query': null });
      }
    },
    trackPurchaseCompleted () {
      if (this.facebookTrackingId) {
        this.$information.track.purchaseCompletedWithFacebook();
      }
      this.$information.track.purchaseCompletedWithGoogle();
    },
    showTransactionMessage ({ message, type }) {
      this.$information.alert({
        showClose: true,
        message,
        type,
        duration: this.$CONSTANTS.USER_MESSAGE_DURATIONS.NORMAL
      });
    },
    alertSignInMandatory () {
      this.$information.alert({
        message: this.$t('authenticate.loginFirst'),
        showClose: true
      });
    },
    redirectToProductDetailsPage (productId) {
      this.$router.push({ name: 'productDetails', params: { id: productId } }).catch(() => {});
    },
    redirectToPayment (paymentLink) {
      this.setAppState({
        newState: this.$CONSTANTS.APP_STATE.LOADING,
        loadingMessage: this.$t('products.loadingPurchase')
      });
      window.location.href = paymentLink;
    },
    redirectToSignIn (productId = null) {
      const query = { to: 'cart' };
      if (productId) query.productId = productId;
      this.$router.replace({ name: 'signIn', query });
    },
    redirectToHome () {
      this.$router.push({
        name: 'home',
        query: {
          isSuccess: true
        }
      });
    },
    async registerWallet () {
      try {
        const walletLink = await this.$services.HTTP.user.manageWallet(window.location.href);

        if (this.facebookTrackingId) {
          this.$information.track.addPaymentInfoWithFacebook();
        }
        this.$information.track.addPaymentInfoWithGoogle();
        this.redirectToWallet(walletLink);
      }catch(err) {
        this.$information.capture({ err });
        this.$information.alert({
          message: this.$t('app.error')
        });
      }
    },
    redirectToWallet (walletLink) {
      window.location.href = walletLink;
    },
    zoneForTos (productZone) {
      if (this.loggedInUser) {
        return productZone || this.userDomainInfo.zone;
      } else {
        return null;
      }
    }
  }
};
