<template>
  <el-row class="product-purchase">
    <el-form
      v-if="isProductPurchaseFormShown"
      :model="purchaseForm"
      :rules="purchaseFormRules"
      ref="productPurchaseForm"
    >
      <el-col>
        <el-row
          class="confirmation-modal__gift-configs-container product-purchase__segment"
          v-if="isGiftConfigSectionShown"
        >
          <p>{{ $t('giftCard.explanation1') }}</p>
          <p>{{ $t('giftCard.explanation2') }}</p>
          <el-form-item
            :label="$t('email')"
            prop="email"
          >
            <el-input
              type="email"
              name="email"
              v-model="purchaseForm.email"
            />
          </el-form-item>
          <el-form-item
            :label="$t('giftCard.message')"
            prop="giftMessage"
          >
            <el-input
              type="textarea"
              :rows="2"
              :placeholder="$t('giftCard.messagePlaceholder')"
              v-model="purchaseForm.giftMessage">
            </el-input>
          </el-form-item>
        </el-row>
        <el-row
          class="flex justify-center confirmation-modal__quantity-container product-purchase__segment"
          v-if="isQuantitySectionShown"
        >
          <InputNumber long :disabled="productPriceWithPromotion !== undefined || lockQuantity" :min="1" v-model="purchaseForm.quantity"/>
        </el-row>
        <el-row
          class="confirmation-modal__promo-code-container product-purchase__segment"
        >
          <div class="cursor-pointer px-sm md:px-0" @click="promotionOpen = !promotionOpen">
            <span class="px-sm">{{ $t('cart.promotion.message') }}</span>
            <i class="fa" :class="promotionOpen ? 'fa-chevron-up'  : 'fa-chevron-down'"/>
          </div>
          <div
            v-if="promotionOpen"
            class="promo-code-container__input-container"
          >
              <div>{{ $t("products.promoCode") }} :</div>
            <div>
              <el-input v-model="purchaseForm.promoCode"> </el-input>
            </div>
            <div>
              <ButAriButtonton
                class="confirmation-modal__promo-code-apply"
                :content="$t('apply')"
                text
                @click="onApplyPromotion()"
              />
            </div>
          </div>
        </el-row>
        <el-row
          class="product-purchase__account-balance product-purchase__segment"
          v-if="isPaymentWithAccountBalanceShown"
        >
          <el-checkbox v-model="purchaseForm.useCredit">
            {{$t('account.balance.useQuestion')}}
          </el-checkbox>
        </el-row>
        <el-row
          class="product-purchase__installments product-purchase__segment"
          v-if="isInstallmentsSectionShown"
        >
          <div class="installments__radio-item">
            <el-radio
              v-model="purchaseForm.isFullPayment"
              :label="1"
              border
              @click="onUpdatePaymentType(0)"
            >{{
              $t("products.buySinglePayment")
              }}</el-radio>
          </div>
          <div class="installments__radio-item">
            <el-radio
              v-model="purchaseForm.isFullPayment"
              :label="0"
              border
              @click="onUpdatePaymentType(1)"
            >{{
              $t("products.buyInstallements")
              }}</el-radio>
          </div>

        </el-row>
        <el-row
          class="product-purchase__pricing product-purchase__segment"
        >
          <PriceSummary
            v-if="purchaseForm.isFullPayment != null"
            :paymentAmounts="paymentAmounts"
            :quantity="purchaseForm.quantity"
            :useCredit="purchaseForm.useCredit"
          />
        </el-row>
        <el-row
          class="product-purchase__tos font-size-13 product-purchase__segment"
          @click="onShowTos"
        >
          <el-col v-if="hasZoneTos">
            <span>{{ $t("products.acceptTos") }}</span><br />
            <span class="strong-link">{{ zoneNameForTos }}</span>
          </el-col>
          <el-col v-else>
            {{ $t("products.noTosFound") }}
          </el-col>
        </el-row>
      </el-col>
    </el-form>
    <ModalAlertPaymentWithInstallments
      @on-cancel="showModalSchedulePayment = false"
      @on-validate="onValidateModalPaymentWithInstallments"
      :visible="showModalSchedulePayment">
    </ModalAlertPaymentWithInstallments>
  </el-row>
</template>

<script>
import ProductsControl from '@/modules/products/mixins/productsControl.js';
import PriceSummary from './PriceSummary.vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import ModalAlertPaymentWithInstallments from './ModalAlertPaymentWithInstallments.vue';
import { findNoWalletInstallment } from '@/utils/products';

export default {
  name: 'ProductPurchase',

  mixins: [ProductsControl],

  components: {
    ModalAlertPaymentWithInstallments,
    PriceSummary
  },

  props: {
    hasZoneTos: Boolean
  },

  mounted () {
    this.productPurchaseFormOpened({
      showProductPurchaseForm: this.showProductPurchaseForm,
      isProductGiftCard: this.isProductGiftCard,
      showGiftConfigSection: this.showGiftConfigSection,
      isQuantityAvailable: this.isQuantityAvailable,
      showQuantitySection: this.showQuantitySection,
      isProductOfferedWithInstallments: this.isProductOfferedWithInstallments,
      showInstallmentsSection: this.showInstallmentsSection,
      memberHasPositiveAccountBalance: this.memberHasPositiveAccountBalance,
      showPaymentWithAccountBalanceSection: this.showPaymentWithAccountBalanceSection,
      initPurchaseFormWithNoInstallments: this.initPurchaseFormWithNoInstallments
    });
    if (this.pendingResa?.recommendedSubscriptions.length) {
      this.pendingResa.recommendedSubscriptions.forEach(rs => {
        if (this.productToBuy.name === rs.name && this.productToBuy.id === rs.id) {
          this.purchaseForm.quantity = rs.quantity;
          this.lockQuantity = true;
        }
      });
    }
  },

  data () {
    return {
      promotionOpen: false,
      lockQuantity: false,
      isProductPurchaseFormShown: false,
      isGiftConfigSectionShown: false,
      isQuantitySectionShown: false,
      isInstallmentsSectionShown: false,
      isPaymentWithAccountBalanceShown: false,
      purchaseForm: {
        quantity: 1,
        isFullPayment: null,
        promoCode: '',
        email: undefined,
        giftMessage: undefined,
        useCredit: false
      },
      purchaseFormRules: {
        email: [
          {
            message: this.$t('authenticate.pleaseInputGiftEmail'),
            trigger: 'change',
            validator: this.checkGiftConfigEmail
          },
          {
            message: this.$t('authenticate.pleaseInputGiftEmail'),
            trigger: 'blur',
            validator: this.checkGiftConfigEmail
          }
        ]
      },
      productPriceWithPromotion: undefined,
      productSchedulerWithPromotion: null,
      showModalSchedulePayment: false
    };
  },
  watch: {
    initiatePayment () {
      if (this.initiatePayment) {
        this.onBuyProduct().catch((error) => {
          this.SET_USE_CREDIT(false);
          this.SET_INITIATE_PAYMENT(false);
          this.$information.capture({ error, inform: true });
          this.$information.alert({
            message: this.$t('calendar.errors.GENERAL_ERROR')
          });
        });
      }
    }
  },

  computed: {
    ...mapGetters('domain', ['getActiveZone']),
    ...mapState('user', ['userDomainInfo', 'userBalance', 'bookingDuringPurchase']),
    ...mapGetters('user', ['loggedInUser']),
    ...mapGetters('config', ['facebookTrackingId']),
    ...mapState('cart', ['productToBuy', 'initiatePayment', 'pendingResa']),
    isVisible: {
      get () {
        return this.visibility;
      },
      set () {
        this.$emit('on-cancel');
      }
    },
    totalPrice () {
      return this.productToBuy.totalPrice * this.purchaseForm.quantity;
    },
    paymentAmounts () {
      if (this.purchaseForm.isFullPayment) {
        return [
          {
            paymentDate: this.$datetime.toISODate(new Date()),
            price: this.productPriceWithPromotion || this.productToBuy.totalPrice
          }
        ];
      } else {
        return this.productSchedulerWithPromotion || this.productToBuy.scheduler || [];
      }
    },
    hasNoWallet () {
      return (
        this.loggedInUser &&
        !this.purchaseForm.isFullPayment &&
        !this.wallet?.cardsList?.length
      );
    },
    zoneNameForTos () {
      const zoneForTos = this.zoneForTos(this.productToBuy.zone);
      if (zoneForTos && this.loggedInUser) {
        const zone = this.getActiveZone(zoneForTos);
        return zone?.clubName;
      } else {
        return false;
      }
    },
    memberHasPositiveAccountBalance () {
      return this.userBalance?.balance > 0;
    }
  },

  methods: {
    ...mapActions('domain', ['setAppState']),
    ...mapMutations('cart', ['SET_INITIATE_PAYMENT', 'SET_USE_CREDIT']),
    productPurchaseFormOpened ({
      showProductPurchaseForm, isProductGiftCard, isQuantityAvailable, showGiftConfigSection, showQuantitySection, isProductOfferedWithInstallments, showInstallmentsSection,
      memberHasPositiveAccountBalance, showPaymentWithAccountBalanceSection, initPurchaseFormWithNoInstallments
    }) {
      showProductPurchaseForm();
      if (isProductGiftCard()) {
        showGiftConfigSection();
      }
      if (isQuantityAvailable()) {
        showQuantitySection();
      }
      if (isProductOfferedWithInstallments()) {
        showInstallmentsSection();
      } else {
        initPurchaseFormWithNoInstallments();
      }
      if (!isProductGiftCard() && memberHasPositiveAccountBalance) {
        showPaymentWithAccountBalanceSection();
      }
    },
    showProductPurchaseForm () {
      this.isProductPurchaseFormShown = true;
    },
    showGiftConfigSection () {
      this.isGiftConfigSectionShown = true;
    },
    showQuantitySection () {
      this.isQuantitySectionShown = true;
    },
    showInstallmentsSection () {
      this.isInstallmentsSectionShown = true;
    },
    showPaymentWithAccountBalanceSection () {
      this.isPaymentWithAccountBalanceShown = true;
    },
    initPurchaseFormWithNoInstallments () {
      this.purchaseForm.isFullPayment = 1;
      this.$emit('on-update-payment-type', 0);
    },
    onValidateModalPaymentWithInstallments () {
      const purchaseDetails = this.getPurchaseDetails();
      this.onBuy(purchaseDetails);
    },
    async onApplyPromotion () {
      try {
        const { data } = await this.$services.HTTP.product.getProductWithPromotion(
          {
            productId: this.productToBuy.id,
            code: this.purchaseForm.promoCode
          }
        );
        this.productPriceWithPromotion = data.promotion.price;
        this.productSchedulerWithPromotion = data.scheduler;
        this.purchaseForm.quantity = 1; // On force la quantité à 1 : impossible d'acheter via un code promo plus d'1 prestation
      } catch (err) {
        this.$information.alert({
          message: this.$t('errors.wrongPromoCode')
        });
      }
    },
    trackAddToBasket () {
      if (this.facebookTrackingId) {
        this.$information.track.addToBasketWithFacebook(this.productToBuy.name);
      }
      this.$information.track.addToBasketWithGoogle(this.productToBuy.name);
    },
    async validatePurchaseForm () {
      const isFormValid = await this.$refs.productPurchaseForm.validate();
      if (!isFormValid) {
        throw new Error();
      }
    },
    async onBuyProduct () {
      this.trackAddToBasket();
      if (!this.loggedInUser) {
        this.alertSignInMandatory();
        return this.redirectToSignIn(this.productToBuy.id);
      }
      await this.validatePurchaseForm();
      this.SET_USE_CREDIT(this.purchaseForm.useCredit);
      if (!this.purchaseForm.isFullPayment && !this.isSchedulerNotWallet()) {
        this.showModalSchedulePayment = true;
        this.SET_INITIATE_PAYMENT(false);
      } else {
        return this.onBuy({
          products: this.getPurchaseDetails(),
          returnURL: this.returnURLForPurchase,
          useCredit: this.purchaseForm.useCredit
        });
      }
    },
    getPurchaseDetails () {
      const purchaseDetails = {
        productId: this.productToBuy.id,
        quantity: this.purchaseForm.quantity,
        cashPayment: this.purchaseForm.isFullPayment
      };
      if (this.purchaseForm.promoCode) {
        purchaseDetails.code = this.purchaseForm.promoCode;
      }
      if (this.productToBuy.promotionId) {
        purchaseDetails.promotionId = this.productToBuy.promotionId;
      }
      if (this.productToBuy.type === this.$CONSTANTS.PRODUCT_TYPES.GIFT_CARD) {
        purchaseDetails.giftConfig = {
          email: this.purchaseForm.email,
          message: this.purchaseForm.giftMessage
        };
        purchaseDetails.isGift = true;
      }
      if (this.bookingDuringPurchase) {
        purchaseDetails.booking = { id: this.bookingDuringPurchase.id };
        if (this.bookingDuringPurchase.status === this.$CONSTANTS.STATUSES.FULL) {
          purchaseDetails.booking.isQueueing = 1;
        }
        if (this.bookingDuringPurchase.placeId) {
          purchaseDetails.booking.placeId = this.bookingDuringPurchase.placeId;
        }
      }
      return [purchaseDetails];
    },
    onShowTos () {
      this.$emit('show-tos');
    },
    checkGiftConfigEmail (rule, value, callback) {
      if (this.productToBuy.type === this.$CONSTANTS.PRODUCT_TYPES.GIFT_CARD && !value) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    },
    isProductGiftCard () {
      return this.productToBuy.type === this.$CONSTANTS.PRODUCT_TYPES.GIFT_CARD;
    },
    isQuantityAvailable () {
      return this.productToBuy.type === this.$CONSTANTS.PRODUCT_TYPES.PREPAID_CARD2 &&
        !(this.productToBuy.upgradeToMemberCat && this.productToBuy.needMemberCat) &&
        !(this.productToBuy.scheduler && this.productToBuy.scheduler.length);
    },
    isSchedulerNotWallet () {
      if (this.productToBuy?.scheduler && this.productToBuy.scheduler.length) {
        return this.productToBuy.scheduler.find(findNoWalletInstallment);
      }
      return false;
    },
    isProductOfferedWithInstallments () {
      return this.productToBuy.scheduler;
    },
    onUpdatePaymentType (value) {
      this.$emit('on-update-payment-type', value);
    }

  }
};
</script>

<style lang="less">
@import '../../../assets/styles/vars.less';

.installments__radio-item{
  margin-top: 5px;
  margin-bottom: 5px;

}

.product-purchase {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  .el-input,
  .el-input-number {
    margin-bottom: 10px;
  }
  .el-radio {
    margin-right: 0;
  }
  .confirmation-modal__promo-code-container{
    .promo-code-container__input-container{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .product-purchase__segment {
    padding: 10px 0px 15px 0px;
    &.product-purchase__installments{
      label{
        span{
          font-size: @small-text-size;
        }
      }
    }
  }
  .product-purchase__tos {
    padding-bottom: 15px;
  }
}
</style>
